import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UnauthorizedUser.css';
import notFoundImage from './UnauthorizedUserIcon.jpg';
import { Image, Spinner } from "react-bootstrap";
import HomePageIcon from "./HomePageIcon.jpg";

const LoadingSpinner = () => {
    return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );
};

const UnauthorizedUser = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleGoHome = () => {
        navigate('/');
    };

    useEffect(() => {
        // Simulate a network check
        const timer = setTimeout(() => {
            setIsLoading(false); // Set loading to false after 2 seconds
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="unauthorized-container">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <img src={notFoundImage} alt="Unauthorized Access" className="unauthorized-image" />
                    <h1 className="unauthorized-title">403 Unauthorized Access</h1>
                    <p className="unauthorized-message">You do not have permission to access this page.</p>
                    <Image
                        src={HomePageIcon}
                        alt="Go Home"
                        className='home-page-icon'
                        onClick={handleGoHome}
                    />
                </>
            )}
        </div>
    );
};

export default UnauthorizedUser;
