import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./admindashboard.css";
import ToasterS from "../../Customer Panel/Toaster";
import ToasterF from "../../Customer Panel/ToasterF";
import LoadingSpinner from "../../Loader/Loader";
import api from "../../../api";

const AdminDashboard = () => {
  const [countData, setCountData] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const [showToastFail, setShowToastFail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const response = await api.get(
          "/AdminPanelCount.php"
        );
        const data = response.data.data;
        setCountData(data);
      } catch (error) {
        setToastMessage("Error fetching count.");
        setShowToastFail(true);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, []);

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className="container-fluid">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      {/* Page Heading */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800 dashhead">Dashboard</h1>
      </div>

      {loading ? (
        <LoadingSpinner/> // Show loader while loading
      ) : (
        <>
          {/* Content Row */}
          <div className="row justify-content-center m-1">
            {/* Total Customers Card */}
            <div
              className="col-xl-5 col-lg-5 col-md-6 mb-4 m-2"
              onClick={() => handleCardClick("/admin-panel/customer-list")}
            >
              <div className="card shadow h-100 py-2 cardborder">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs fw-bold text-primary text-uppercase mb-1">
                        Total Customers
                      </div>
                      <div className="h5 mb-0 fw-bold mx-4">
                        {countData.totalCustomerCount}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-users fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Total Massagers Card */}
            <div
              className="col-xl-5 col-lg-5 col-md-6 mb-4 m-2"
              onClick={() => handleCardClick("/admin-panel/massager-list")}
            >
              <div className="card shadow h-100 py-2 cardborder3">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs fw-bold text-danger text-uppercase mb-1">
                        Total Massagers
                      </div>
                      <div className="h5 mb-0 fw-bold mx-4">
                        {countData.totalMassagerCount}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-user-tie fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="row justify-content-center m-1">
            {/* Pending Orders Card */}
            <div
              className="col-xl-5 col-lg-5 col-md-6 mb-4 m-2"
              onClick={() =>
                handleCardClick("/admin-panel/pending-order-report")
              }
            >
              <div className="card shadow h-100 py-2 cardborder2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs fw-bold text-warning text-uppercase mb-1">
                        Pending Orders
                      </div>
                      <div className="h5 mb-0 fw-bold mx-4">
                        {countData.totalPendingStatus}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-hourglass-half fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Accepted Orders Card */}
            <div
              className="col-xl-5 col-lg-5 col-md-6 mb-4 m-2"
              onClick={() =>
                handleCardClick("/admin-panel/accepted-order-report")
              }
            >
              <div className="card shadow h-100 py-2 cardborder1">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs fw-bold text-success text-uppercase mb-1">
                        Accepted Orders
                      </div>
                      <div className="h5 mb-0 fw-bold mx-4">
                        {countData.totalAcceptedStatus}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-check-circle fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Third Row */}
          <div className="row justify-content-center m-1">
            {/* Completed Orders Card */}
            <div
              className="col-xl-5 col-lg-5 col-md-6 mb-4 m-2"
              onClick={() =>
                handleCardClick("/admin-panel/completed-order-report")
              }
            >
              <div className="card shadow h-100 py-2 cardborder4">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs fw-bold text-info text-uppercase mb-1">
                        Completed Orders
                      </div>
                      <div className="h5 mb-0 fw-bold mx-4">
                        {countData.totalCompletedStatus}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-check-double fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
