import React from "react";
import "./SidebarFooterIcon.css";
import { useState } from "react";
import { Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
import BookNowGif from "./8db5b7_b96cea69c9564e1f86469277f901163b~mv2.gif";
import { NavLink } from "react-router-dom";
import CustomerRegistractionForm2 from "../../Customer Panel/Customer_Registraction_Form2";

const SidebarFooterIcon = () => {
  const [showBookingModal, setShowBookingModal] = useState(false); // State for booking modal


  const openBookingModal = () => {
    setShowBookingModal(true);

  };

  const closeBookingModal = () => {
    setShowBookingModal(false);
  };

  return (
    <div className="Sidebar_footer_icon">
      {/* <a href="#" className="Sidebar_footer_icon_link"><i className="fab fa-facebook-f"></i></a>
      <a href="#" className="Sidebar_footer_icon_link"><i className="fab fa-twitter"></i></a>
      <a href="#" className="Sidebar_footer_icon_link"><i className="fab fa-instagram"></i></a>
      <a href="#" className="Sidebar_footer_icon_link"><i className="fab fa-linkedin"></i></a> */}
      {/* <a href="/customerRegistraction" className="Sidebar_footer_icon_link  Sidebar_footer_icon_Booknow  "><i className="fas fa-shopping-cart"></i></a> */}
      {/* <NavLink  to={"/customerRegistration"} className="Sidebar_footer_icon_link  Sidebar_footer_icon_Booknow  " > <i className="fas fa-shopping-cart"> </i></NavLink> */}
      <NavLink
        className="Sidebar_footer_icon_link   Sidebar_footer_icon_Booknow   "
        onClick={openBookingModal}
      >
        <Image
          src={BookNowGif}
          alt="Small Thumbnail"
          style={{ width: "50px", height: "auto" }}
        ></Image>
      </NavLink>
      
      <a
        href="https://www.facebook.com/people/Shrikrishna-Arogyam/100093267866282/?paipv=0&eav=AfZ_PEZMuFt_65oNZkSi8nd39FZaPVzEBXe9C6ed62cTXo2bfRYyy5vFC6FfxnjWRF4"
        className="Sidebar_footer_icon_link  Sidebar_footer_icon_Calling"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-facebook-f  Slide_feb__icon"></i>
      </a>

      <a
        href="tel:+919766690774"
        className="Sidebar_footer_icon_link  Sidebar_footer_icon_Calling"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fas fa-phone Footer_Phone_icon"></i>
      </a>

       <a
        href="https://wa.me/919766690774?text=Hello"
        className="Sidebar_footer_icon_link  Sidebar_footer_icon_whatsapp  "
        rel="noreferrer"
        target="_blank"
      >
        <i className="fab fa-whatsapp"></i>
      </a>
      <a href="https://www.instagram.com/shri_krishnaarogyam/" className="Sidebar_footer_icon_link  Sidebar_footer_icon_iNSTAGRAN  "  rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
      <a
        href="https://www.youtube.com/channel/UCsUN2f53Zvw2UtaIOirzowg"
        className="Sidebar_footer_icon_link Sidebar_footer_icon_Youtub "
        rel="noreferrer"
        target="_blank"
      >
        <i className="fab fa-youtube"></i>
      </a>

      {showBookingModal && (
        <CustomerRegistractionForm2 
          show={showBookingModal}
          handleClose={closeBookingModal}
        />
      )}
    </div>
  );
};

export default SidebarFooterIcon;
