import React from 'react';
import Sidebar from './Sidebar';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from './Admin_Component/Dashboard';
import CustomerList from './Admin_Component/CustomerList';
import MassagerList from './Admin_Component/MassagerList';
import AddVideo from './Admin_Component/AddVideo';
import Report from './Admin_Component/Report';
import './AdminPanel.css';  // New CSS file to control layout
import PendingOrderReport from './Admin_Component/PendingOrderReport';
import AcceptedOrderReport from './Admin_Component/AcceptedOrderReport';
import CompletedOrderReport from './Admin_Component/CompletedOrderReport';

const AdminPanel = () => {
    return (
        <div className="admin-panel">

            <Sidebar />
            <div className="admin-content">
                <main>
                    <Routes>
                        <Route index element={<AdminDashboard />} />
                        <Route path="admindashboard" element={<AdminDashboard />} />
                        <Route path="customer-list" element={<CustomerList />} />
                        <Route path="massager-list" element={<MassagerList />} />
                        <Route path="add-video" element={<AddVideo />} />
                        <Route path="report" element={<Report />} />
                        <Route path="pending-order-report" element={<PendingOrderReport />} />
                        <Route path="accepted-order-report" element={<AcceptedOrderReport />} />
                        <Route path="completed-order-report" element={<CompletedOrderReport />} />


                    </Routes>
                </main>
            </div>
        </div>
    );
};

export default AdminPanel;
