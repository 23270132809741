// Footer.js
import React from "react";
import "./Footer.css";
// import Footerimg from "./Navlogo.jpg";
import Footerimg from "./shreekrushna-logo-min.png";
import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import GoogleIcon from '@mui/icons-material/Google';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import GitHubIcon from '@mui/icons-material/GitHub';
const Footer = () => {
  return (
    <footer className="footer">
      {/* <div className="footer-top">
        <div className="social-icons">
          <span>Get connected with us on social networks:</span>
          <div className="icons footer_icon">
  <a href="https://www.facebook.com/people/Shrikrishna-Arogyam/100093267866282/?paipv=0&eav=AfZ_PEZMuFt_65oNZkSi8nd39FZaPVzEBXe9C6ed62cTXo2bfRYyy5vFC6FfxnjWRF4" className="icon" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f  Footer_Febsbook_icon"></i></a>
  <a href="tel:+919766690774" className="icon" target="_blank" rel="noopener noreferrer"><i className="fas fa-phone Footer_Phone_icon"></i></a>
  <a href="https://www.youtube.com/channel/UCsUN2f53Zvw2UtaIOirzowg" className="icon" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube Footer_youtub_icon"></i></a>
  <a href="https://www.instagram.com/shri_krishnaarogyam/" className="icon" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram Footer_Insta_icon"></i></a>
  <a href="https://www.linkedin.com" className="icon" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin Footer_Linkdin_icon"></i></a>
  <a href="https://wa.me/919766690774?text=Hello" className="icon" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp Footer_whatsapp_icon"></i></a>
</div>

        </div>
      </div> */}

      <div className="footer-content">
        <div className="footer-section company">
          <h5>COMPANY NAME</h5>
          <p>ShriKrishna Arogyam Massage And Therapy Treatments</p>
          <Image src={Footerimg} className="FooterImg" />
        </div>

        <div className="footer-section Componayproducts">
          <h5>Treatments</h5>
          <ul>

          <NavLink
              to={"/Waxing"}
              className="Footer_Tritment_links"
            >
              <li>Waxing</li>
            </NavLink>

            <NavLink to={"/neuro-therapy"} className="Footer_Tritment_links">
              <li>Neuro Therapy</li>
            </NavLink>


            <NavLink
              to={"/chiropractic-adjustment"}
              className="Footer_Tritment_links"
            >
              {" "}
              <li>Chiropractic Adjustment</li>
            </NavLink>

            <NavLink
              to={"/bone-alignment-therapy"}
              className="Footer_Tritment_links"
            >
              {" "}
              <li>Bone Alignment Therapy</li>
            </NavLink>

            <NavLink to={"/cupping-therapy"} className="Footer_Tritment_links">
              {" "}
              <li> Cupping Therapy [ Hijama ]</li>{" "}
            </NavLink>

            <NavLink
              to={"/advance-massage-therapy"}
              className="Footer_Tritment_links"
            >
              {" "}
              <li>Advanced Massage Therapy</li>
            </NavLink>

            

          </ul>
        </div>

        <div className="footer-section  gogole_link">
          <h5>Google Reviews </h5>
          {/* <iframe 
        src="https://maps.app.goo.gl/9ZYoUurcN4JTm4jf9" 
        width="600" 
        height="450" 
        style={{ border: 0 }} 
        allowFullScreen="" 
        loading="lazy" 
        title="Google Reviews Map"
      ></iframe> */}
          {/* <div class='sk-ww-google-reviews' data-embed-id='25466445'></div><script src='https://widgets.sociablekit.com/google-reviews/widget.js' async defer></script> */}
          <iframe
            src="https://widgets.sociablekit.com/google-reviews/iframe/25470946"
            title="google review"
            frameborder="0"
            width="100%"
            height="1000"
            className="google_revive"
          ></iframe>
          {/* <iframe title="image" src='https://e9cf671a1f864c99898862c722872541.elf.site' frameborder='3' width='100%' height='80%' scrolling="yes"></iframe> */}
        </div>

        <div className="footer-section  contact">
          <h5>CONTACT</h5>
          <p>
            <i className="fas fa-home"></i> S, NO.82/30/1/31/1sagar, colony, DP
            Rd, near Srishti apartment, Sagar Colony, Guruganesh Nagar, Kothrud,
            Pune, Maharashtra 411038
          </p>
          <p>
            <i className="fas fa-envelope"></i> kpatil1808@gmail.com
          </p>
          <p>
            <i className="fas fa-phone"></i>+91 97666 90774
          </p>
          <p>
            <i className="fas fa-print"></i> + 01 234 567 89
          </p>
        </div>
      </div>

      <div className="footer-bottom">
        <span className="ShriKrishna-name">
          <span className="kgf">©2024 Copyright : </span>
          ShriKrishna Arogyam Massage And Therapy Treatments
        </span>

        <span className="nebula-color">
          <span className="kgf">Developed By -</span>N
          <span className="e-color">e</span>bula Technology
        </span>
      </div>
    </footer>
  );
};

export default Footer;
