import React from 'react';
import './ContactUs.css'; // Make sure to create and link a CSS file



const ContactUs = () => {

    return (
        <div className="Contact_Us_container">
            <section className="Contact_Us_header">
                <h1 className="Contact_Us_title">CONTACT US</h1>
                <p className="Contact_Us_description">
                    Greetings And Welcome To Shrikrishna Arogyam Massage And Therapy Centre. We Provide A Wide Array Of Treatments Designed To Revitalize Your Body And Mind. We Customize Every Treatment To Match Your Specific Wellness Needs, Whether It Is A Soothing Massage Or Specialized Therapy.
                </p>
            </section>

            <section className="Contact_Us_content">
                <div className="Contact_Us_left">
                    <div className='Contact_us_left_child'>
                        <h2 className="Contact_Us_subtitle">Get in touch</h2>
                        <iframe
                            className="Contact_Us_map"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d121074.42227107589!2d73.80072100000001!3d18.502869!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf76115c72ef%3A0x90dcd2da33076c49!2sShriKrishnaArogyam%20Massage%20Therapy!5e0!3m2!1sen!2sin!4v1727077484424!5m2!1sen!2sin"
                            width="300"
                            height="200"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Map"
                        ></iframe>
                    </div>
                </div>

                <div className="Contact_Us_right">
                    <div className='Contact_us_right_child'>
                        <strong>
                            <h1 className="Contact_Us_subtitle">Contact Info</h1>
                        </strong>
                        <p className="Contact_Us_address">
                            <strong>
                                <a href="kpatil1808@gmail.com" className="icon_Contact_Add" target="_blank" rel="noopener noreferrer">
                                    <i className="fas fa-map-marker-alt Footer_Icon"></i></a> Address:
                            </strong>
                            S, NO.82/30/1/31/1sagar, colony, DP Rd, near Srishti apartment, Sagar Colony, Guruganesh Nagar, Kothrud, Pune, Maharashtra 411038
                        </p>
                        <p className="Contact_Us_phone">
                            <strong>
                                <a href="tel:+919766690774" className="icon_contact_phone" target="_blank" rel="noopener noreferrer">
                                    <i className="fas fa-phone Footer_Phone_icon"></i>
                                </a>
                                Mobile Number:
                            </strong>
                            <a href="tel:+919766690774">+91 97666 90774</a>
                        </p>
                        <p className="Contact_Us_Email">
                            <strong>
                                <a href="kpatil1808@gmail.com" className="icon_Contact_mail" target="_blank" rel="noopener noreferrer">
                                    <i className="fas fa-envelope "></i></a> Email:
                            </strong>
                            <a href="mailto:kpatil1808@gmail.com">kpatil1808@gmail.com</a>
                        </p>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContactUs;
