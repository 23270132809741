
import axios from 'axios';

// Create an instance of axios with the base URL
const api = axios.create({
  baseURL: 'https://www.shrikrishnaarogyammassagetherapy.com/ShreeKrushnaProject', // replace with your actual base URL
  headers: {
    'Content-Type': 'application/json',
    // Add other default headers if needed
  },
});


api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally (e.g., logging, showing notifications)
    return Promise.reject(error);
  }
);

export default api;
