// ConfirmBox.js
import React from 'react';
import './ConfirmBox.css';

const ConfirmBox = ({ show, message, onConfirm, onCancel }) => {
  if (!show) return null;

  return (
    <div className="confirm-box-overlay">
      <div className="confirm-box">
        <div className="confirm-box-header">Confirmation</div>
        <div className="confirm-box-message">{message}</div>
        <div className="confirm-box-buttons">
          <button
            className="confirm-box-button confirm-box-button-no"
            onClick={onCancel}
          >
            No
          </button>
          <button
            className="confirm-box-button confirm-box-button-yes"
            onClick={onConfirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;
