import React, { useState, useEffect } from 'react';
import './Report.css';
import pdfdownload from './pdf-download.svg';
import ToasterS from '../../Customer Panel/Toaster';
import ToasterF from '../../Customer Panel/ToasterF';
import Paginate from '../../Pagegnation/Pagination'; // Import Pagination component
import LoadingSpinner from '../../Loader/Loader';
import api from '../../../api';

const Report = () => {
  // State for holding fetched data, date selectors, and loading
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [toastMessage, setToastMessage] = useState("");
  const [showToastFail, setShowToastFail] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [itemsPerPage] = useState(10); // Items per page
  const [totalItems, setTotalItems] = useState(0); // Total items from the backend

  // Function to get today's date in 'YYYY-MM-DD' format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  // Function to get the first day of the current month in 'YYYY-MM-DD' format
  const getStartOfMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return startOfMonth.toISOString().split('T')[0];
  };

  // Set default dates when the component mounts
  useEffect(() => {
    const today = getTodayDate();
    const startOfMonth = getStartOfMonth();
    setStartDate(startOfMonth);
    setEndDate(today);
    fetchData(startOfMonth, today, currentPage, itemsPerPage);
  }, []);

  // Function to fetch the data from the backend
  const fetchData = (start_date, end_date, page, perPage) => {
    const payload = {
      start_date: start_date,
      end_date: end_date,
      report_type: 'general',
      page: page,
      per_page: perPage,
    };

    setLoading(true); // Start loading
    api.post('/generate_customerReport.php', payload)
      .then(response => {

        if (Array.isArray(response.data.data)) {
          if (response.data.data.length === 0) {
            setToastMessage("Data not found");
            setShowToastFail(true);
          } else {
            const data1 = response.data.data;
            setData(data1);
            setToastMessage("Data retrieved successfully");
            setShowToast(true);
            setTotalItems(response.data.totalRecords); // Set total items for pagination
          }
        } else {
          setData([]); // Reset data in case of invalid response
          setToastMessage("Unexpected response format");
          setShowToastFail(true);
        }
      })
      .catch(error => {
        setToastMessage("An error occurred while fetching data");
        setShowToastFail(true);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  // Function to handle the submit button click and fetch data based on the selected dates
  const handleDateSubmit = () => {
    fetchData(startDate, endDate, currentPage, itemsPerPage); // Fetch data with current pagination state
  };

  // Function to handle PDF download
  const handleDownloadPdf = () => {
    if (!data || data.length === 0) {
      setToastMessage("Data not found. PDF cannot be downloaded.");
      setShowToastFail(true);
      return;
    }

    const payload = {
      start_date: startDate,
      end_date: endDate,
    };

    setLoading(true); // Start loading
    api.post('/report.php', payload, {
      responseType: 'blob',
    })
      .then(response => {
        setLoading(false); // Stop loading
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Total_customer_report.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setToastMessage("PDF downloaded successfully");
        setShowToast(true);
      })
      .catch(error => {
        setLoading(false); // Stop loading
        setToastMessage("An error occurred while downloading PDF");
        setShowToastFail(true);
      });
  };

  // Handle page change for pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(startDate, endDate, page, itemsPerPage); // Fetch data with the new page
  };

  if(loading){
    return(
      <LoadingSpinner/>
    )
  }

  return (
    <div className="Report_Admin_Page_container">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />

      <h2 className="Report_Admin_Page_heading">Total Customer Reports</h2>

      {/* Date selectors */}
      <div className="Report_Admin_Page_dateSelectors">
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="Report_Admin_Page_input"
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="Report_Admin_Page_input"
          />
        </label>
        <div className="Report_Admin_Page_buttonContainer">
          <button onClick={handleDateSubmit} className="Report_Admin_Page_button">
            Submit Dates
          </button>
        </div>

        {/* Download PDF Icon */}
        <div className="Report_Admin_Page_gifContainer">
          <img
            src={pdfdownload}
            alt="Download PDF"
            onClick={handleDownloadPdf}
            className="Report_Admin_Page_downloadGif"
          />
        </div>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="loading-spinner40">
          <div className="spinner"></div>
        </div>
      )}

      {/* Table for displaying fetched data */}
      <table className="Report_Admin_Page_table">
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Address</th>
            <th>Issue</th>
            <th>Therapy</th>
            <th>Age</th>
            <th>Gender</th>
            <th>PrefGender</th>
            <th>Booing Time</th>
            <th>Appointment</th>
            <th>Massager Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) && data
            .slice()
            .sort((a, b) => new Date(b.time) - new Date(a.time))
            .map((entry, index) => (
              <tr key={index}>
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td>{entry.c_name}</td>
                <td>{entry.c_contact}</td>
                <td>{entry.c_email}</td>
                <td>{entry.c_address}</td>
                <td>{entry.c_issue}</td>
                <td>{entry.c_therapy}</td>
                <td>{entry.c_age}</td>
                <td>{entry.c_gender}</td>
                <td>{entry.c_prefgender}</td>
                <td>{entry.c_time}</td>
                <td>{entry.c_appointment}</td>
                <td>{entry.massager_name != null ? entry.massager_name : <p>Not Assign</p>} </td>
                <td>
                  {entry.request_status !== null ? (
                    <span className={`badge ${entry.request_status === "completed" ? "bg-success": entry.request_status === "pending" ? "bg-warning" : entry.request_status === "accepted" ? "bg-primary" : ""}`}>
                      {entry.request_status}
                    </span>
                  ) : (
                    <span className="badge bg-secondary">Pending</span>
                  )}
                </td>

              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Component */}
      <Paginate
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Report;
