import React  from "react";
import { Card, Image } from "react-bootstrap";
import "./BenefitsMassage.css"; // Import custom CSS
// Import your images
import image1 from "./Flaticon.jpg";
import image2 from "./Cupping.jpg";
import image3 from "./FootMassage.jpg";
import image4 from "./HeadMassage.jpg";
import image5 from "./posture.jpg";
import image6 from "./waist.jpg";
import image7 from "./muscles.jpg";
import image8 from "./lotus.jpg";

const BenefitsMassage = () => {
  const cardsData = [
    {
      id: 1,
      title: "Relieve Pain",
      image: image1,
      info: "Experience soothing relief from chronic discomfort with our expert massage therapies tailored to your needs.",
    },
    {
      id: 2,
      title: "Reduce Spasms",
      image: image2,
      info: "Alleviate muscle spasms and tension with targeted massage techniques designed to promote relaxation and flexibility.",
    },
    {
      id: 3,
      title: "Prevent Injuries",
      image: image3,
      info: "Experience personalized therapy sessions designed to accelerate your injury recovery process effectively and safely.",
    },
    {
      id: 4,
      title: "Movement Range",
      image: image4,
      info: "Expand your range of motion and flexibility through therapeutic massage, enabling smoother and more fluid movement.",
    },
    {
      id: 5,
      title: "Improved Posture",
      image: image5,
      info: "Enhance your posture and overall well-being with personalized massage sessions that focus on alignment and balance.",
    },
    {
      id: 6,
      title: "Reduce Waist",
      image: image6,
      info: "Trim inches and sculpt your waistline with treatments designed to promote detoxification and slimming.",
    },
    {
      id: 7,
      title: "Muscles Healthy",
      image: image7,
      info: "Maintain optimal muscle health and function with regular massage sessions that promote circulation and relieve tension.",
    },
    {
      id: 8,
      title: "Promote Healing",
      image: image8,
      info: "Accelerate your body's natural healing mechanisms with our holistic approach to massage and therapy.",
    },
  ];

  return (
    <div className="Benifitmain">
      <div className="massage-header">
        <center>
          <div className="header-content">
            <h1 className="massage-h1">BENEFITS OF MASSAGE</h1>
            <h4 className="massage-description">
              Massage offers numerous benefits, including relaxation and stress
              reduction, which can improve overall mental well-being. It helps
              relieve muscle tension and pain, enhances circulation, and
              promotes flexibility. Regular massage can also boost immune
              function and improve sleep quality, contributing to a greater
              sense of balance and vitality in daily life.
            </h4>
          </div>
        </center>
      </div>
      <div className="massage-background massageBg">
        <div className="massage-card-grid">
          {cardsData.map((card) => (
            <div className="massage-flip-card" key={card.id}>
              <div className="massage-flip-card-inner">
                {/* Front of the card */}
                <div className="massage-flip-card-front">
                  <Card className="massage-card">
                    <div className="massage-circular-image-container">
                      <Image src={card.image} />
                    </div>
                    <Card.Body className="massage-body">
                      <Card.Title className="card-title">
                        {card.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </div>

                {/* Back of the card */}
                <div className="massage-flip-card-back">
                  <Card.Body className="massage-body">
                    <Card.Text className="card-text">{card.info}</Card.Text>
                  </Card.Body>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BenefitsMassage;
