import { GETMASSAGEREMAIL, GETMASSAGERID } from "./type/type"

export const GatMassagerEmailAction = (data) => {

  return {
    type: GETMASSAGEREMAIL,
    payload: data

  }
}



export const GetMassagerIdAction = (data) => {

  return {
    type: GETMASSAGERID,
    payload: data

  }
}