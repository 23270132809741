import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles }) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const userRole = localStorage.getItem('userRole');

  if (!isAuthenticated) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/" />;
  } else if (!allowedRoles.includes(userRole)) {
    // If the user role is not authorized, redirect to an unauthorized page
    return <Navigate to="/unauthorized" />;
  }

  // If authenticated and authorized, render the child components (Outlet)
  return <Outlet />;
};

export default ProtectedRoute;
