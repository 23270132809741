import React, { Suspense } from 'react';
import './Aboutus.css';
import AboutDiscroptionimg from './couple_spa_at_hablis.avif';
import { Image } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import SampleGallary from './Gallary/SampleGallary';
import LoadingSpinner from '../Loader/Loader';
import SampleGallary1 from './Gallery1/SampleGallery1';

// Lazy load the YouTube video component
const YoutubeVideoCarousel1 = React.lazy(() => import('./Youtub_Video_links/Youtub_video'));



const AboutUs = () => {
    return (
        <div className="About_us_page_container">
            <section className="About_us_page_header">
                <h1 className="About_us_page_title">About Us</h1>
                <h5>At ShriKrishna Arogyam Massage And Therapy Treatments, we are dedicated to providing professional, personalized massage therapy services tailored to meet the needs of each client. Our team of certified and experienced massage therapists is committed to promoting relaxation, well-being, and overall health through a variety of techniques. Whether you're seeking stress relief, pain management, or simply a moment of peace, our goal is to help you achieve balance and rejuvenation. Experience the art of healing with us today.</h5>
            </section>
            
            {/* Lazy-loaded YouTube video carousel */}
            <section className='About_us_Video'>
                <Suspense fallback={<LoadingSpinner />}>
                    <YoutubeVideoCarousel1/>
                </Suspense>
            </section>
            
            <section className="About_Us_content">
                <div className='About_us_ParaDiscription'>
                    <p>The Shrikrishna Arogyam Massage & Therapy Center is a place where wisdom 
                        and well-being collide. Having served the community for 
                        more than five years, we take great pride in offering massage and therapy services that are customized to meet your specific needs. To encourage relaxation, reduce discomfort, and improve general well-being, our team of knowledgeable therapists blends age-old methods with innovative approaches. At Shrikrishna Arogyam, we put your comfort and happiness first, making sure that each session is a healing experience. Our facility provides a peaceful haven where you can unwind and regain mental and physical harmony, whether your goals are stress reduction, releasing tense muscles, or just treating yourself. At Shrikrishna Arogyam Massage and Therapy Center, discover the difference this is where your path to perfect health begins.
                    </p>
                </div>
                <div className='About_Us_img_div'>
                    <Col sm={12} className=' Img_About_us_discription'>
                        <Image src={AboutDiscroptionimg} className ='IMG_About_sec_dev' />
                    </Col>
                </div>
            </section>
            <SampleGallary/>
            <SampleGallary1/>
        </div>
    );
};

export default AboutUs;
