import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import "./MassagerList.css"; // Import the CSS file for styling
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddMassager from "./AddMassager";
import ToasterS from "../../Customer Panel/Toaster";
import ToasterF from "../../Customer Panel/ToasterF";
import LoadingSpinner from "../../Loader/Loader";
import ConfirmBox from "../../ConfarmBox/ConfirmBox";
import api from "../../../api";
const MassagerList = () => {
  const [massagers, setMassagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedMassager, setSelectedMassager] = useState(null);
  const [searchFilter, setSearchFilter] = useState({ name: "", address: "" });
  const [showAddMassagerModal, setShowAddMassagerModal] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [massagerToDelete, setMassagerToDelete] = useState();
  const [updatedData, setUpdatedData] = useState({
    m_id: "",
    name: "",
    contact: "",
    email: "",
    address: "",
    age: "",
  });
  const [errors, setErrors] = useState({});

  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const [showToastFail, setShowToastFail] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);



  // Fetch data from the API when the component mounts
  const fetchData = async () => {
    setLoading(true); // Set loading to true before the request
    try {
      const response = await api.get(
        "/AdminPanelMassagerList.php"
      );
      if (response.data.status === "success") {
        setMassagers(response.data.data);
      } else {
        setError("Failed to fetch data. Please try again later.");
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false); // Set loading to false after the request
    }
  };

  useEffect(() => {
    fetchData(); // Call fetchData when the component mounts
  }, []);

  // Function to handle when a new massager is added
  const handleAddMassager = () => {
    fetchData(); // Re-fetch massager list after adding a new one
  };

  // Handle opening the modal and setting the selected massager data
  const handleEdit = (massager) => {
    setSelectedMassager(massager); // Set selected massager
    setUpdatedData({
      m_id: massager.id, // Ensure id is included
      name: massager.name,
      contact: massager.contact,
      email: massager.email,
      address: massager.address,
      age: massager.age,
    });
    setShowModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({ ...prevData, [name]: value }));

    let newErrors = { ...errors };

    if (name === "name") {
      if (!/^[a-zA-Z\s]*$/.test(value)) return; // Allow only letters and spaces
      if (value.trim()) delete newErrors.name; // Clear error if valid
    }

    if (name === "contact") {
      if (!/^\d*$/.test(value)) return; // Allow only digits
      if (value.length === 10) delete newErrors.contact; // Clear error if valid
    }

    if (name === "email") {
      if (!/\S+@\S+\.\S+/.test(value)) {
        newErrors.email = "Please enter a valid email address.";
      } else {
        delete newErrors.email; // Clear error if valid
      }
    }

    if (name === "address") {
      if (value.trim() === "") {
        newErrors.address = "Address is required.";
      } else {
        delete newErrors.address; // Clear error if valid
      }
    }

    if (name === "age") {
      if (
        !/^\d*$/.test(value) ||
        parseInt(value, 10) <= 0 ||
        parseInt(value, 10) >= 120
      )
        return; // Allow digits, restrict age to 1-120
      if (value) delete newErrors.age; // Clear error if valid
    }

    setErrors(newErrors);
  };

  const validateInputs = () => {
    const newErrors = {};

    // Validate name
    if (!/^[a-zA-Z\s]*$/.test(updatedData.name)) {
      newErrors.name = "Name can only contain letters and spaces.";
    }

    // Validate contact
    if (!/^\d{10}$/.test(updatedData.contact)) {
      newErrors.contact = "Contact must be a 10-digit number.";
    }

    // Validate email
    if (!/\S+@\S+\.\S+/.test(updatedData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Validate address
    if (!updatedData.address.trim()) {
      newErrors.address = "Address is required.";
    }

    // Validate age
    if (
      !/^\d*$/.test(updatedData.age) ||
      parseInt(updatedData.age, 10) <= 0 ||
      parseInt(updatedData.age, 10) >= 120
    ) {
      newErrors.age = "Age must be a below 121.";
    }

    return newErrors; // Return the errors object
  };

  // Handle saving the updated massager information
  // const handleSave = async () => {
  //   try {
  //     const response = await api.post(
  //       "/update_massager.php",
  //       updatedData
  //     );
  //     setMassagers((prevMassagers) =>
  //       prevMassagers.map((massager) =>
  //         massager.id === selectedMassager.id
  //           ? { ...massager, ...updatedData }
  //           : massager
  //       )
  //     );
  //     setToastMessage("Massager updated successfully");
  //     setShowToast(true);
  //     setShowModal(false);
  //   } catch (err) {

  //     setToastMessage("Failed to update massager. Please try again.");
  //     setShowToastFail(true);
  //   }
  // };
  const handleSave = async () => {
    setErrors({}); // Clear previous errors
    const validationErrors = validateInputs();

    // Check if the name field is empty
    if (!updatedData.name.trim()) {
      validationErrors.name = "Name cannot be empty.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set the error messages
      return; // Exit if there are validation errors
    }

    setIsUpdating(true);

    try {
      const response = await api.post(
        "/update_massager.php",
        updatedData
      );
   

     
        setMassagers((prevMassagers) =>
          prevMassagers.map((massager) =>
            massager.id === selectedMassager.id
              ? { ...massager, ...updatedData }
              : massager
          )
        );

        setToastMessage("Massager updated successfully");
        setShowToast(true);
        setShowModal(false);
     
    } catch (err) {
      setToastMessage("Failed to update massager. Please try again.");
      setShowToastFail(true);
    } finally {
      setIsUpdating(false); // Stop loading
    }
  };

  const handleDelete = (massager) => {
    setMassagerToDelete(massager); // Store massager ID to be deleted
    setShowConfirmBox(true); // Show confirmation box
  };
  const confirmDelete = async () => {
    if (massagerToDelete) {
      const massagerid = { m_id: massagerToDelete }; // Use the selected massager's id
      try {
        const response = await api.post("/delete_massager.php", massagerid);
        if (response.data.status === "massager deleted") {
          setToastMessage("Massager deleted successfully.");
          setShowToast(true);
          setMassagers(massagers.filter((m) => m.id !== massagerToDelete));
        } else {
          setToastMessage("Failed to delete massager. Please try again.");
          setShowToastFail(true);
        }
      } catch (error) {
        setToastMessage("Failed to delete massager. Please try again.");
        setShowToastFail(true);
      } finally {
        setShowConfirmBox(false); // Hide confirmation box
        setMassagerToDelete(null); // Clear the selected massager
      }
    }
  };
  // const handleDelete = async (massager) => {
  //   const massagerid = { m_id: massager }; // Use the selected massager's id

  //   try {
  //     const response = await api.post(
  //       "/delete_massager.php",
  //       massagerid
  //     );

  //     if (response.data.status === "massager deleted") {
  //       // alert(`Massager with ID: ${massagerid.m_id} deleted successfully`);
  //       setToastMessage(`Massager deleted successfully..`);
  //       setShowToast(true);
  //       setMassagers(massagers.filter((m) => m.id !== massager));
  //       // Update massagers state

  //     } else {
  //       // alert("Failed to delete massager. Please try again.");
  //       setToastMessage("Failed to delete massager. Please try again.");
  //       setShowToastFail(true);

  //     }
  //   } catch (error) {
  //     setToastMessage("Failed to delete massager. Please try again.");
  //     setShowToastFail(true);
  //     setLoading(false);

  //   }
  // };

  const applyFilters = async (name, address) => {
    setLoading(true); // Set loading to true before the request
    try {
      let nameResponse = [];
      let addressResponse = [];

      // If both name and address are provided, make two API calls
      if (name && address) {
        const nameFilter = { name: name };
        const addressFilter = { address: address };

        const [nameData, addressData] = await Promise.all([
          api.post(
            "/mas_name_filter.php",
            nameFilter
          ),
          api.post(
            "/mas_add_filter.php",
            addressFilter
          ),
        ]);

        nameResponse = nameData.data.data;
        addressResponse = addressData.data.data;

        const filteredData = nameResponse.filter((customer) =>
          addressResponse.some((addr) => addr.id === customer.id)
        );

        if (filteredData.length > 0) {
          setMassagers(filteredData);
        } else {
          // alert("No results found for the given name and address.");
          setToastMessage("No results found for the given name and address.");
          setShowToastFail(true);
        }
      }
      // If only address is entered
      else if (address) {
        const addressFilter = { address: address };
        const response = await api.post(
          "/mas_add_filter.php",
          addressFilter
        );
        addressResponse = response.data.data;
        if (response.data.status === "success") {
          setMassagers(addressResponse);
        } else {
          // alert("No results found for the given address.");
          setToastMessage("No results found for the given address.");
          setShowToastFail(true);
        }
      }
      // If only name is entered
      else if (name) {
        const nameFilter = { name: name };
        const response = await api.post(
          "/mas_name_filter.php",
          nameFilter
        );
        nameResponse = response.data.data;

        if (response.data.status === "success") {
          setMassagers(nameResponse);
        } else {
          // alert("No results found for the given name.");
          setToastMessage("No results found for the given name.");
          setShowToastFail(true);
        }
      }
    } catch (err) {
      // alert("Failed to fetch data. Please try again later.");
      setToastMessage("Failed to fetch data. Please try again later.");
      setShowToastFail(true);
    } finally {
      setLoading(false); // Set loading to false after the request
    }
  };

  const clearSearchFilters = async () => {
    setLoading(true); // Set loading to true before the request
    try {
      const response = await api.get(
        "/AdminPanelMassagerList.php"
      );
      setMassagers(response.data.data);
      setSearchFilter({ name: "", address: "" });
    } catch (err) {
      // alert("Failed to fetch data. Please try again later.");
      setToastMessage("Failed to fetch data. Please try again later.");
      setShowToastFail(true);
    } finally {
      setLoading(false); // Set loading to false after the request
    }
  };

  const showAddMassagerModalfunction = () => {
    setShowAddMassagerModal(true);
  };



  const handleCloseAddMassagerModal = () => {
    setShowAddMassagerModal(false);
  };

  if (loading || isUpdating)
    return (
      <LoadingSpinner />
    );

  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div className="table-container">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      <h2>Massager's List</h2>

      {/* Search bars */}
      <div className="row mb-3 p-2 justify-content-end">
        <div className="col-md-2 col-sm-6 col-12 mt-2 mt-md-0">
          <Button variant="primary mb-2" onClick={showAddMassagerModalfunction}>
            Add Massager
          </Button>

        </div>
        <div className="col-md-2 col-sm-6 col-12">
          <Form.Group controlId="searchName">
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={searchFilter.name}
              onChange={(e) =>
                setSearchFilter({ ...searchFilter, name: e.target.value })
              }
            />
          </Form.Group>
        </div>
        <div className="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0">
          <Form.Group controlId="searchAddress">
            <Form.Control
              type="text"
              placeholder="Enter address"
              value={searchFilter.address}
              onChange={(e) =>
                setSearchFilter({ ...searchFilter, address: e.target.value })
              }
            />
          </Form.Group>
        </div>

        <div className="col-md-2 col-sm-6 col-12 mt-2 mt-md-0">
          <Button
            variant="primary"
            className="w-100"
            onClick={() =>
              applyFilters(searchFilter.name, searchFilter.address)
            }
          >
            Apply Filters
          </Button>
        </div>

        <div className="col-md-2 col-sm-6 col-12 mt-2 mt-md-0">
          <Button
            variant="primary"
            className="w-100"
            onClick={clearSearchFilters}
          >
            Clear Filters
          </Button>
        </div>
      </div>

      <table className="mresponsive-table">
        <thead>
          <tr>
            <th>Sr.No.</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Age</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
          {massagers
            .slice()
            .reverse()
            .map((massager, index) => (
              <tr key={massager.m_id}>
                <td>{index + 1}</td>
                <td>{massager.name}</td>
                <td>{massager.contact}</td>
                <td>{massager.email}</td>
                <td>{massager.gender}</td>
                <td>{massager.address}</td>
                <td>{massager.age}</td>
                <td>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="action-icon hand-action-icon"
                    onClick={() => handleEdit(massager)}
                  />
                  &nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="action-icon hand-action-icon"
                    onClick={() => handleDelete(massager.id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Modal for editing massager information */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Massager</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Massager-list-edit-form">
          <Form>
            <Form.Group controlId="formName">
              <Form.Label className="fw-bold">
                Name: <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                pattern="[a-zA-Z\s]*"
                value={updatedData.name}
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formContact">
              <Form.Label className="fw-bold">
                Contact: <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={updatedData.contact}
                onChange={handleChange}
                maxLength="10"
                // pattern="[0-9]{10}"
                isInvalid={!!errors.contact}
              />
              <Form.Control.Feedback type="invalid">
                {errors.contact}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label className="fw-bold">
                Email: <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={updatedData.email}
                onChange={handleChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formAddress">
              <Form.Label className="fw-bold">
                Address: <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={updatedData.address}
                onChange={handleChange}
                isInvalid={!!errors.address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formAge">
              <Form.Label className="fw-bold">
                Age: <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="age"
                maxLength={3}
                value={updatedData.age}
                onChange={handleChange}
                isInvalid={!!errors.age}
              />
              <Form.Control.Feedback type="invalid">
                {errors.age}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button> */}
          {/* <Button 
      variant="primary" 
      onClick={handleSave} 
      disabled={isUpdating} 
      className="btn-loading"
    >
      {isUpdating && <Spinner animation="border" size="sm" className="spinner-position" />}
      Save Changes
    </Button> */}
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isUpdating}
            className="action-save-button d-flex align-items-center"
          >
            {isUpdating && <Spinner animation="border" size="sm" className="loading-icon" />}
            <span className={isUpdating ? "status-text ms-2" : ""}>
              {isUpdating ? "Saving..." : "Save Changes"}
            </span>
          </Button>

        </Modal.Footer>
      </Modal>

      <AddMassager
        show={showAddMassagerModal}
        handleClose={handleCloseAddMassagerModal}
        onAddMassager={handleAddMassager} // Pass the callback function
      />
      <ConfirmBox
        show={showConfirmBox}
        message={"Are you sure you want to delete the massager"}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmBox(false)}
      />
    </div>
  );
};

export default MassagerList;
