import React from "react"; // Import useState and useEffect
import { Col, Row } from "react-bootstrap"; // Import Spinner for loader
import { FaRegHandPaper } from "react-icons/fa";
import { IoMdBody } from "react-icons/io";
import { MdOutlineAccessTime } from "react-icons/md";
import { GiNecklace } from "react-icons/gi";
import "./ChiropracticAdjustment.css";
import Tilt from "react-parallax-tilt";
// Image imports
import spinal_Adjustment from "./Images/Spinal_Adjustment.avif";
import neck_Adjustment1 from "./Images/Neck_Adjustment1.jpg";
import back_Adjustment from "./Images/Back_Adjustment.webp";
import joint_Mobilization from "./Images/Joint_Mobilization.avif";
import postural_Correction from "./Images/Postural_Correction.jpg";
import sports_Chiropractic1 from "./Images/Sports_Chiropractic1.jpg";
import TMJ_Adjustment from "./Images/TMJ_Adjustment.jpg";
import pediatric_Chiropractic from "./Images/Pediatric_Chiropractic.png";
import pregnancy_Chiropractic1 from "./Images/Pregnancy_Chiropractic1.jpg";
import chronic_Pain_Management1 from "./Images/Chronic_Pain_Management1.jpg";
import spinal_Decompression from "./Images/Spinal_Decompression.jpg";
import functional_Movement_Assessment1 from "./Images/Functional_Movement_Assessment1.jpg";

// Define the chiropractic treatments array
const treatments = [
  {
    id: 1,
    name: "Spinal Adjustment",
    description: "Realigns the spine to improve function and relieve pain.",
    icon: <IoMdBody />,
    bgimg: spinal_Adjustment,
  },
  {
    id: 2,
    name: "Neck Adjustment",
    description:
      "Targets misalignments in the neck to alleviate discomfort and improve mobility.",
    icon: <GiNecklace />,
    bgimg: neck_Adjustment1,
  },
  {
    id: 3,
    name: "Back Adjustment",
    description:
      "Focuses on the thoracic and lumbar spine to relieve tension and pain.",
    icon: <FaRegHandPaper />,
    bgimg: back_Adjustment,
  },
  {
    id: 4,
    name: "Joint Mobilization",
    description: "Improves range of motion and reduces joint stiffness.",
    icon: <MdOutlineAccessTime />,
    bgimg: joint_Mobilization,
  },
  {
    id: 5,
    name: "Postural Correction",
    description:
      "Helps correct poor posture through targeted adjustments and exercises.",
    icon: <IoMdBody />,
    bgimg: postural_Correction,
  },
  {
    id: 6,
    name: "Sports Chiropractic",
    description:
      "Tailored adjustments to enhance athletic performance and prevent injuries.",
    icon: <FaRegHandPaper />,
    bgimg: sports_Chiropractic1,
  },
  {
    id: 7,
    name: "TMJ Adjustment",
    description:
      "Addresses issues with the temporomandibular joint to relieve jaw pain.",
    icon: <GiNecklace />,
    bgimg: TMJ_Adjustment,
  },
  {
    id: 8,
    name: "Pediatric Chiropractic",
    description:
      "Gentle adjustments designed specifically for children’s spinal health.",
    icon: <FaRegHandPaper />,
    bgimg: pediatric_Chiropractic,
  },
  {
    id: 9,
    name: "Pregnancy Chiropractic",
    description:
      "Specialized adjustments to support a healthy pregnancy and alleviate related discomfort.",
    icon: <GiNecklace />,
    bgimg: pregnancy_Chiropractic1,
  },
  {
    id: 10,
    name: "Chronic Pain Management",
    description:
      "Focused adjustments to help manage and alleviate chronic pain conditions.",
    icon: <IoMdBody />,
    bgimg: chronic_Pain_Management1,
  },
  {
    id: 11,
    name: "Spinal Decompression",
    description:
      "Non-surgical technique to relieve pressure on spinal discs and nerves.",
    icon: <MdOutlineAccessTime />,
    bgimg: spinal_Decompression,
  },
  {
    id: 12,
    name: "Functional Movement Assessment",
    description:
      "Evaluates movement patterns to identify and address functional limitations.",
    icon: <IoMdBody />,
    bgimg: functional_Movement_Assessment1,
  },
];

const ChiropracticAdjustment = () => {
  return (
    <div className="chiro-treatments-container">
      <h1 className="chiro-treatments-heading">Chiropractic Adjustments</h1>
      <Row className="chiro-treatments-list">
        {treatments.map((treatment) => (
          <Col xs={6} md={4} lg={3} key={treatment.id} className="mb-4">
            <Tilt
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              scale={1.05}
              transitionSpeed={250}
            >
              <div className="chiro-treatment-fullscreen">
                <div className="chiro-treatment-image">
                  <img src={treatment.bgimg} alt={treatment.name} />
                </div>
                <div className="chiro-treatment-message">
                  <div className="chiro-icon-container">{treatment.icon}</div>
                  <h3>{treatment.name}</h3>
                  <p>{treatment.description}</p>
                </div>
              </div>
            </Tilt>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ChiropracticAdjustment;
