import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import loginbg from './bglogin.png';
import loginlogo from './login-logo2.png';
import './login.css';

const RoleSelection = ({ show, handleClose, onRoleSelect }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className="custom-modal-header">
            <img src={loginlogo} alt="login-logo" className="login-logo me-2"></img>

                <Modal.Title className='modaltitle me-5' >Select Your Role</Modal.Title>
            </Modal.Header>
            <Modal.Body 
            style={{
                backgroundImage: `url(${loginbg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontFamily: "var(--firstChoiceFont)",

            }}
            className="d-flex justify-content-around">
                <Button onClick={() => onRoleSelect('admin')}>Admin</Button>
                <Button onClick={() => onRoleSelect('massager')}>Massager</Button>
            </Modal.Body>
        </Modal>
    );
};

export default RoleSelection;
