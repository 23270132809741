import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom"; // Import NavLink
import "./TreatmentNav.css";

const treatments = [
  {
    path: "/advance-massage-therapy",
    title: "Advance Massage Therapy",
    description:
      "Experience advanced techniques in massage therapy to rejuvenate your body.",
  },
  {
    path: "/neuro-therapy",
    title: "Neuro Therapy",
    description: "Specialized therapy for neurological well-being and health.",
  },
  {
    path: "/chiropractic-adjustment",
    title: "Chiropractic Adjustment",
    description:
      "Align your spine and improve your posture with expert chiropractic care.",
  },
  {
    path: "/cupping-therapy",
    title: "Cupping Therapy [ Hijama ]",
    description:
      "Traditional cupping therapy to relieve pain and enhance circulation.",
  },
  {
    path: "/bone-alignment-therapy",
    title: "Bone Alignment Therapy",
    description:
      "Therapies to align and balance your bone structure for optimal health.",
  },
  {
    path: "/Waxing",
    title: "Waxing",
    description:
      "Waxing is an effective hair removal method that leaves skin smooth for weeks by removing hair from the root.",
  },
];

const TreatmentNav = () => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    const animationTimer = setTimeout(() => setAnimated(true), 200);
    return () => clearTimeout(animationTimer);
  }, []);

  return (
    <div className="page-background">
      <div className="treatment-nav-wrapper tre-nav-wrap">
        <Container style={{ fontFamily: "Indie Flower" }}>
          <Row className="text-center mb-2">
            <Col>
              <h1 className="nav-treatment-header">OUR TREATMENTS</h1>
              <p className="nav-treatment-info">
                We provide a wide array of treatments designed to revitalize
                your body and mind. We customize every treatment to match your
                specific wellness needs, whether it is a soothing massage or
                specialized therapy.
              </p>
            </Col>
          </Row>

          <Row className="treatment-cards-container">
            {treatments.map((treatment) => (
              <Col md={4} sm={6} key={treatment.path}>
                <Card
                  className={`nav-treatment-card ${
                    animated ? "card-animate" : ""
                  }`}
                >
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <div>
                      <Card.Title className="nav-tret-title">
                        {treatment.title}
                      </Card.Title>
                      <Card.Text className="nav-tret-text">
                        {treatment.description}
                      </Card.Text>
                    </div>
                    <NavLink to={treatment.path} className="card-link">
                      Learn More
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TreatmentNav;
