import React, { useState, useEffect } from 'react';
import './Report.css'; // Assuming you will create a CSS file for styles
import ToasterS from '../../Customer Panel/Toaster'; // Import success toaster
import ToasterF from '../../Customer Panel/ToasterF'; // Import failure toaster
import pdfdownload from './pdf-download.svg'; // Your PDF download icon
import Paginate from '../../Pagegnation/Pagination'; // Import Pagination component
import api from '../../../api';

import LoadingSpinner from '../../Loader/Loader';

const AcceptedOrderReport = () => {
  // State for holding fetched data, date selectors, and loading
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [toastMessage, setToastMessage] = useState("");
  const [showToastFail, setShowToastFail] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [itemsPerPage] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total items for pagination

  // Function to fetch the data based on start and end date and pagination
  const fetchData = (start_date, end_date, page, perPage) => {
    const payload = {
      start_date: start_date,
      end_date: end_date,
      report_type: 'accepted',
      page: page, // Current page
      per_page: perPage // Records per page
    };

    setLoading(true); // Start loading

    api.post('/generate_customerReport.php', payload)
      .then(response => {

        if (Array.isArray(response.data.data)) {
          if (response.data.data.length === 0) {
            setToastMessage("Data not found");
            setShowToastFail(true);
          } else {
            setData(response.data.data);
            setToastMessage("Data retrieved successfully");
            setShowToast(true);
            setTotalItems(response.data.totalRecords); // Set total items for pagination
          }
        } else {

          setData([]); // Reset data in case of invalid response
          setToastMessage("Unexpected response format");
          setShowToastFail(true);
        }
      })
      .catch(error => {
        setToastMessage("An error occurred while fetching data");
        setShowToastFail(true);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  // Function to get the first day of the current month in 'YYYY-MM-DD' format
  const getStartOfMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return startOfMonth.toISOString().split('T')[0];
  };

  // Set default dates when the component mounts
  useEffect(() => {
    const today = getTodayDate();
    const startOfMonth = getStartOfMonth();
    setStartDate(startOfMonth); // Set start date to the first day of the current month
    setEndDate(today); // Set end date to today's date
    fetchData(startOfMonth, today, currentPage, itemsPerPage); // Fetch data for the first day of the month to today
  }, []);

  // Function to download PDF
  const handleDownloadPdf = () => {
    if (!data || data.length === 0) {
      setToastMessage("Data not found. PDF cannot be downloaded.");
      setShowToastFail(true);
      return; // Exit if there's no data
    }

    const payload = {
      start_date: startDate,
      end_date: endDate
    };

    api.post('/Acceptedcuspdf.php', payload, {
      responseType: 'blob', // Important to handle binary data
    })
      .then(response => {
        // Create a URL for the PDF blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'accepted_report.pdf'); // Specify the name of the file
        document.body.appendChild(link);
        link.click(); // Simulate click to trigger download
        link.remove(); // Clean up
        setToastMessage("PDF downloaded successfully");
        setShowToast(true);
      })
      .catch(error => {
        setToastMessage("An error occurred while downloading PDF");
        setShowToastFail(true);
      });
  };

  // Handle page change for pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(startDate, endDate, page, itemsPerPage); // Fetch data with the new page
  };

  if(loading){
    return(
     <LoadingSpinner/>
    )
  }

  return (
    <div className="Report_Admin_Page_container">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />

      <h2 className="Report_Admin_Page_heading">Accepted Customer Reports</h2>

      {/* Date selectors */}
      <div className="Report_Admin_Page_dateSelectors">
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="Report_Admin_Page_input"
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="Report_Admin_Page_input"
          />
        </label>
        {/* Submit button */}
        <div className="Report_Admin_Page_buttonContainer">
          <button onClick={() => fetchData(startDate, endDate, currentPage, itemsPerPage)} className="Report_Admin_Page_button">
            Submit Dates
          </button>
        </div>

        <div className="Report_Admin_Page_buttonContainer">
          <img
            src={pdfdownload}
            alt="Download PDF"
            onClick={handleDownloadPdf}
            className="Report_Admin_Page_downloadGif"
          />
        </div>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="loading-spinner40">
          <div className="spinner"></div>
        </div>
      )}

      {/* Table for displaying fetched data */}
      <table className="Report_Admin_Page_table">
        <thead>
          <tr>
            <th>Sr.No</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Address</th>
            <th>Issue</th>
            <th>Therapy</th>
            <th>Age</th>
            <th>Gender</th>
            <th>PrefGender</th>
            <th>Booking Time</th>
            <th>Appointment</th>
            <th> Massager Name</th>


          </tr>
        </thead>
        <tbody>
          {data
            .slice()
            .sort((a, b) => new Date(b.time) - new Date(a.time))
            .map((entry, index) => (
              <tr key={index}>
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td>{entry.customer_name}</td>
                <td>{entry.customer_contact}</td>
                <td>{entry.customer_email}</td>
                <td>{entry.customer_address}</td>
                <td>{entry.customer_issue}</td>
                <td>{entry.customer_therapy}</td>
                <td>{entry.age}</td>
                <td>{entry.customer_gender}</td>
                <td>{entry.prefgender}</td>
                <td>{entry.request_time}</td>
                <td>{entry.appointment}</td>
                <td>{entry.massager_name}</td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Component */}
      <Paginate
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default AcceptedOrderReport;
