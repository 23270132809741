import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import logo from "./Navlogo.jpg";
import { BsArrowReturnRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa"; // Import notification bell icon
// Modal for notification
import { Modal, Badge, Tab, Nav } from "react-bootstrap";
import ToasterS from "../Customer Panel/Toaster";
import ToasterF from "../Customer Panel/ToasterF";
// import { useSelector } from "react-redux";
import api from "../../api";

const navItems = [
  { label: "Dashboard", route: "admindashboard", hasSubmenu: false },
  { label: "Customer List", route: "customer-list", hasSubmenu: false },
  { label: "Massager", route: "massager-list", hasSubmenu: false },
  { label: "Feedback", route: "add-video", hasSubmenu: false },
  {
    label: "Report",
    route: "",
    hasSubmenu: true,
    submenu: [
      { label: "Total Order", route: "report" },
      { label: "Accepted Order", route: "accepted-order-report" },
      { label: "Completed Order", route: "completed-order-report" },
      { label: "Pending Order", route: "pending-order-report" },
    ],
  },
];

const navIcons = ["dashboard", "people", "spa", "video_library", "bar_chart"];

const Sidebar = () => {
  const [active, setActive] = useState(null);
  const [showModal, setShowModal] = useState(false); // State for Modal
  const [notifications, setNotifications] = useState([]);
  const [allnotification, setAllnotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0); // For badge count
  const [notread, setRead] = useState(false);
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const [showToastFail, setShowToastFail] = useState(false);
  // const [showUnread, setShowUnread] = useState(false); // State for toggling notification view
  // const massagerEmail = useSelector((state) => state.MassagerValueGat.email);

  useEffect(() => {
    // Fetch notifications from the backend
    // This is just a sample, replace it with actual API call
    const fetchData = async () => {
      try {
        const payload = {
          role: "admin",
          email: "dipaliwadekar27@gmail.com",
          notification: "unread",
        };
        const response = await api.post(
          "/Notification.php",
          payload
        );
        if (response.data.status === "success") {
          const fetchedNotifications = response.data;

          setNotificationCount(fetchedNotifications.data.count);
          // Filter notifications where notify is 'unread'
          const adminNotifications =
            fetchedNotifications.data.notifications.filter(
              (notification) => notification.notify === "unread"
            );
          setNotifications([...adminNotifications].reverse());
          const allNotification = fetchedNotifications.data.notifications;
          setAllnotification([...allNotification].reverse());
        } else {
          setToastMessage("Error fetching notifications.");
          setShowToastFail(true);
        }
      } catch (error) {
        setToastMessage("Error fetching notifications.");
        setShowToastFail(true);
      }
    };

    fetchData();
  }, []);

  const goto = (index, route) => {
    setActive(index);
    navigate(route);
  };

  const gotoSubmenu = (parentIndex, submenuIndex, route) => {
    setActive(`${parentIndex}-${submenuIndex}`);
    navigate(route);
  };

  const Logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleShowNotifications = () => {
    setShowModal(true);
    // Mark all notifications as read when opening modal
    setNotifications(notifications.map((n) => ({ ...n, isRead: true })));
  };

  const handleCloseModal = () => {
    const fetchData = async () => {
      try {
        const payload = {
          role: "admin",
          email: "dipaliwadekar27@gmail.com",
          notification: "read",
        };
        const response = await api.post(
          "/Notification.php",
          payload
        );

        if (response.data.status === "success") {
          setNotificationCount(0); // Reset notification count
          setNotifications([]);
        }
      } catch (error) {
        setToastMessage("Error fetching notifications.");
        setShowToastFail(true);
      }
    };
    if (notread === true) {
      fetchData();
    }

    setShowModal(false);
  };

  const Unread = () => {
    // setShowUnread(true);
    setRead(true);
  };
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Format the date part (DD/MMM/YY)
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short", // Example: 'Oct' for October
      year: "2-digit",
    });

    // Format the time part (HH:MM AM/PM)
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format with AM/PM
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  return (
    <aside className="sidebar">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      <div className="inner">
        <div className="header">
          <img src={logo} alt="logo" className="adminlogo"></img>
          <h1>
            ShriKrishna <span className="ps-5">Aarogyam</span>
          </h1>
        </div>

        <nav className="menu">
          {navItems.map((item, index) => (
            <div className="menu-item" key={item.label}>
              <button
                className={active === index ? "active" : ""}
                onClick={() => !item.hasSubmenu && goto(index, item.route)}
              >
                <span className="material-symbols-outlined">
                  {navIcons[index]}
                </span>
                <p>{item.label}</p>
                {item.hasSubmenu && <FaChevronDown className="dropdown-icon" />}
              </button>

              {item.hasSubmenu && (
                <div className="submenu">
                  {item.submenu.map((subItem, subIndex) => (
                    <button
                      key={subItem.label}
                      className={
                        active === `${index}-${subIndex}` ? "active" : ""
                      }
                      onClick={() =>
                        gotoSubmenu(index, subIndex, subItem.route)
                      }
                    >
                      <span className="submenu-icon">
                        <BsArrowReturnRight />
                      </span>
                      <p>{subItem.label}</p>
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>

        <div className="sidebar-footer">
          <button
            className="footer-btn notification-btn "
            onClick={handleShowNotifications}
          >
            <span className="material-symbols-outlined">notifications</span>
            {notificationCount > 0 && (
              <Badge pill bg="danger" className="notification-badge">
                {notificationCount}
              </Badge>
            )}
            <p>Notification</p>
          </button>

          <button className="footer-btn" onClick={Logout}>
            <span className="material-symbols-outlined">logout</span>
            <p>Log Out</p>
          </button>
        </div>
      </div>

      {/* Notification Modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="notification-modal"
        dialogClassName="modal-right"
        centered
      >
        <Modal.Header closeButton  className="Header_Slider">
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Tab.Container defaultActiveKey="all">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="all">All</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="unread" onClick={() => Unread()}>
                  Unread
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="all">
                {allnotification.length === 0 ? (
                  <p>No notifications</p>
                ) : (
                  <ul>
                    {allnotification.map((notification) => (
                      <li
                        key={notification.id}
                        className={notification.isRead ? "read" : "unread"}
                      >
                        {notification.message === "customer_added"
                          ? `${notification.customerName}  ${
                              notification.message
                            }  on ${formatDateTime(notification.time)} `
                          : ` ${notification.customerName} ${
                              notification.message
                            } by ${
                              notification.massagerName
                            } on ${formatDateTime(notification.time)}`}
                      </li>
                    ))}
                  </ul>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="unread">
                {notifications.length === 0 ? (
                  <p>No unread notifications</p>
                ) : (
                  <ul>
                    {notifications.map((notification) => (
                      <li
                        key={notification.id}
                        className={notification.isRead ? "read" : "unread"}
                      >
                        {notification.message === "customer_added"
                          ? `${notification.customerName}  ${
                              notification.message
                            }  on ${formatDateTime(notification.time)} `
                          : ` ${notification.customerName} ${
                              notification.message
                            } by ${
                              notification.massagerName
                            } on ${formatDateTime(notification.time)}`}
                      </li>
                    ))}
                  </ul>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </aside>
  );
};

export default Sidebar;
