import React, { useState, useEffect } from 'react';
import './MassagerReport.css';
import { useSelector } from 'react-redux';
import ToasterS from '../../Customer Panel/Toaster'; // Success toaster
import ToasterF from '../../Customer Panel/ToasterF'; // Failure toaster
import Paginate from '../../Pagegnation/Pagination'; // Pagination component
import pdfdownload from './pdf-download.svg';
import LoadingSpinner from '../../Loader/Loader';
import api from '../../../api';

const MassagerAcceptOrder = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showToastFail, setShowToastFail] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  // Pagination state
  const [page, setpage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total records from backend

  const MassagerEmail = useSelector((state) => state.MassagerValueGat.email);

  // Helper function to get today's date and the first day of the current month
  const getDefaultDates = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const formatDate = (date) => {
      return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    };

    return {
      firstDay: formatDate(firstDayOfMonth),
      today: formatDate(today),
    };
  };

  // Automatically fetch data when the page loads
  useEffect(() => {
    const { firstDay, today } = getDefaultDates();
    setStartDate(firstDay);
    setEndDate(today);

    // Fetch data automatically for page 1
    fetchData(firstDay, today, 1, itemsPerPage);
  }, []); // Empty dependency array to run only once when the component mounts

  const fetchData = (start_date, end_date, page, records_per_page) => {
    const payload = {
      start_date,
      end_date,
      massager_email: MassagerEmail,
      report_type: 'accepted_customers_by_massager',
      page, // New page number
      records_per_page, // New records per page
    };

    setLoading(true); // Start loading
    api
      .post('/generate_customerofmassager.php', payload)
      .then((response) => {
        const records = response.data.data || []; // Ensure records is an array
        setData(records);
        setTotalItems(response.data.totalRecords || 0); // Ensure total_records has a fallback value

        if (records.length === 0) {
          setToastMessage('Data not found');
          setShowToastFail(true);
        } else {
          setToastMessage('Data retrieved successfully');
          setShowToast(true);
        }
      })
      .catch((error) => {

        setToastMessage('An error occurred');
        setShowToastFail(true);
      })
      .finally(() => {
        setLoading(false); // End loading
      });
  };

  const handleDateSubmit = () => {
    fetchData(startDate, endDate, 1, itemsPerPage); // Reset to page 1 when new dates are submitted
  };

  const handleDownloadPdf = () => {
    if (data.length === 0) {
      setToastMessage('Data not found');
      setShowToastFail(true);
      return; // Prevent further execution if there's no data
    }

    const payload = {
      start_date: startDate,
      end_date: endDate,
      massager_email: MassagerEmail,
    };


    api
      .post('/MassagerAcceptedCustomerReportpdf.php', payload, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Accepted_Report.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {

        setToastMessage("Error submitting dates:");
        setShowToastFail(true);
      });
  };

  // Handle page change
  const handlePageChange = (page) => {
    setpage(page);
    fetchData(startDate, endDate, page, itemsPerPage);
  };

  if(loading){  
    return <LoadingSpinner />
  }

  return (
    <div className="MassagerReport_container">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />

      <h2 className="MassagerReport_heading">Accepted Order</h2>

      <div className="MassagerReport_dateSelectors">
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="MassagerReport_input"
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="MassagerReport_input"
          />
        </label>

        <div className="MassagerReport_buttonContainer">
          <button onClick={handleDateSubmit} className="MassagerReport_button">
            Submit Dates
          </button>
        </div>

        <div className="MassagerReport_buttonContainer">
          <img
            src={pdfdownload}
            alt="Download PDF"
            onClick={handleDownloadPdf}
            className="Report_Massager_Page_downloadGif"
          />
        </div>
      </div>

      
        <table className="MassagerReport_table">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Issue</th>
              <th>Therapy</th>
              <th>Gender</th>
              <th>Age</th>
              <th>PrefGender</th>
              <th>Booking Time</th>
              <th>Appointment</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data
                .slice()
                .sort((a, b) => new Date(b.time) - new Date(a.time))
                .map((entry, index) => (
                  <tr key={index}>
                    <td>{(page - 1) * itemsPerPage + index + 1}</td>
                    <td>{entry.customer_name}</td>
                    <td>{entry.customer_contact}</td>
                    <td>{entry.customer_email}</td>
                    <td>{entry.customer_address}</td>
                    <td>{entry.customer_issue}</td>
                    <td>{entry.customer_therapy}</td>
                    <td>{entry.customer_issue}</td>
                    <td>{entry.age}</td>
                    <td>{entry.prefgender}</td>
                    <td>{entry.request_time}</td>
                    <td>{entry.appointment}</td>

                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="8">No records found</td>
              </tr>
            )}
          </tbody>
        </table>
      

      {/* Pagination Component */}
      <Paginate
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default MassagerAcceptOrder;
