import React from "react"; // Import useEffect and useState
import { Col, Row} from "react-bootstrap"; // Import Spinner
import { FaSpa, FaRegHandPaper } from "react-icons/fa";
import { IoMdCog } from "react-icons/io";
import { MdOutlineAccessibility } from "react-icons/md";
import { GiPressureCooker } from "react-icons/gi";
import "./NeuroTherapy.css";
import Tilt from 'react-parallax-tilt';
// Images import
import ful_bo_joi from './Images/Full_Body_Joints_Pain_Relief_Massage_Neuro.jpg';
import sciatica_Pain_Relief_Massage from './Images/Sciatica_Pain_Relief_Massage.jpg';
import frozen_Shoulder_Massage2 from './Images/Frozen_Shoulder_Massage2.jpg';
import cervical_Pain_Relief_Massage from './Images/Cervical_Pain_Relief_Massage.jpg';
import high_Blood_Pressure_Massage1 from './Images/High_Blood_Pressure_Massage1.jpg';
import knee_Pain_Relief_Massage from './Images/Knee_Pain_Relief_Massage.jpg';
import foot_And_Ankle_Pain_Relief_Massage from './Images/Foot_And_Ankle_Pain_Relief_Massage.jpg';
import stream_bath from './Images/steam_bath.jpg';
import dry_needling from './Images/DryNeedling.avif';
import agni_Karma from './Images/Agni_Karma.webp';
import cervical_Spondylitis_Treatment2 from './Images/Cervical_Spondylitis_Treatment2.jpg';
import ankylosis_G_Spondylitis_Treatment from './Images/Ankylosis_G_Spondylitis_Treatment.avif';
import disc_Bulge_Treatment from './Images/Disc_Bulge_Treatment.webp';
import and_More_Treatments from './Images/And_More_Treatments.jpg';

// Define the neuro therapy treatments array
const treatments = [
  {
    id: 1,
    name: "Full Body Joints Pain Relief Massage",
    description:
      "Targets joint pain throughout the body with specialized techniques and oils.",
    icon: <GiPressureCooker />,
    bgimg: ful_bo_joi,
  },
  {
    id: 2,
    name: "Sciatica Pain Relief Massage",
    description:
      "Focuses on relieving the symptoms of sciatica through targeted massage techniques.",
    icon: <GiPressureCooker />,
    bgimg: sciatica_Pain_Relief_Massage,
  },
  {
    id: 3,
    name: "Frozen Shoulder Massage",
    description:
      "Addresses stiffness and pain in the shoulder with specialized massage techniques.",
    icon: <FaRegHandPaper />,
    bgimg: frozen_Shoulder_Massage2,
  },
  {
    id: 4,
    name: "Cervical Pain Relief Massage",
    description:
      "Helps relieve pain and tension in the cervical (neck) region.",
    icon: <FaRegHandPaper />,
    bgimg: cervical_Pain_Relief_Massage,
  },
  {
    id: 5,
    name: "High Blood Pressure Massage",
    description:
      "Designed to help lower blood pressure through relaxation and stress reduction techniques.",
    icon: <FaSpa />,
    bgimg: high_Blood_Pressure_Massage1,
  },
  {
    id: 6,
    name: "Knee Pain Relief Massage",
    description:
      "Targets pain and discomfort in the knee area with specific massage techniques.",
    icon: <MdOutlineAccessibility />,
    bgimg: knee_Pain_Relief_Massage,
  },
  {
    id: 7,
    name: "Foot And Ankle Pain Relief Massage",
    description:
      "Targets pain and discomfort in the feet and ankles with specialized techniques.",
    icon: <MdOutlineAccessibility />,
    bgimg: foot_And_Ankle_Pain_Relief_Massage,
  },
  {
    id: 8,
    name: "Steam Bath",
    description:
      "Utilizes steam to relax muscles and improve circulation.",
    icon: <FaSpa />,
    bgimg: stream_bath,
  },
  {
    id: 9,
    name: "Dry Needling",
    description:
      "A technique involving the insertion of thin needles into trigger points to relieve pain.",
    icon: <IoMdCog />,
    bgimg: dry_needling,
  },
  {
    id: 10,
    name: "Agni Karma",
    description:
      "An ancient therapy involving the application of heat to relieve pain and inflammation.",
    icon: <FaSpa />,
    bgimg: agni_Karma,
  },
  {
    id: 11,
    name: "Cervical Spondylitis Treatment",
    description:
      "Aims to alleviate the symptoms of cervical spondylitis through specialized treatments.",
    icon: <FaSpa />,
    bgimg: cervical_Spondylitis_Treatment2,
  },
  {
    id: 12,
    name: "Ankylosis G Spondylitis Treatment",
    description:
      "Focused treatment for ankylosing spondylitis to improve mobility and reduce pain.",
    icon: <FaSpa />,
    bgimg: ankylosis_G_Spondylitis_Treatment,
  },
  {
    id: 13,
    name: "Disc Bulge Treatment",
    description:
      "Addresses disc bulges and associated pain with targeted therapy.",
    icon: <FaSpa />,
    bgimg: disc_Bulge_Treatment,
  },
  {
    id: 14,
    name: "And More Treatments...",
    description:
      "Explore additional treatments tailored to your specific needs.",
    icon: <FaSpa />,
    bgimg: and_More_Treatments,
  },
];

const NeuroTherapy = () => {

  return (
    <div className="neuro-treatments-container">
      <h1 className="neuro-treatments-heading">Neuro Therapy</h1>
      <Row className="neuro-treatments-list">
        {treatments.map((treatment) => (
          <Col xs={6} md={4} lg={3} key={treatment.id} className="mb-4">
            <Tilt 
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              scale={1.05}
              transitionSpeed={250}
            >
              <div className="neuro-treatment-fullscreen">
                <div className="neuro-treatment-image">
                  <img src={treatment.bgimg} alt={treatment.name} />
                </div>
                <div className="neuro-treatment-message">
                  <div className="neuro-icon-container">{treatment.icon}</div>
                  <h3>{treatment.name}</h3>
                  <p>{treatment.description}</p>
                </div>
              </div>
            </Tilt>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default NeuroTherapy;
