import React, { useEffect, useState } from "react";
import "./SampleGallary.css";

import Image1 from "./Images/Img1.jpg";
import Image2 from "./Images/Img2.jpg";
import Image3 from "./Images/Img3.jpg";
import Image4 from "./Images/Img4.jpg";
import Image5 from "./Images/Img5.jpg";
import Image6 from "./Images/Img6.jpg";
import Image7 from "./Images/Img7.jpg";
import Image8 from "./Images/Img8.jpg";
import Image9 from "./Images/Img9.jpg";
import Image10 from "./Images/Img10.jpg";
import Image11 from "./Images/Img11.jpg";
import Image12 from "./Images/Img12.jpg";
import Image13 from "./Images/Img13.jpg";
import Image14 from "./Images/Img14.jpg";
import Image15 from "./Images/Img15.jpg";
import Image16 from "./Images/Img16.jpg";
import Image17 from "./Images/Img17.jpg";
import Image18 from "./Images/Img18.jpg";
import Image19 from "./Images/Img19.jpg";
import Image20 from "./Images/Img20.jpg";
import Image21 from "./Images/Img21.jpg";
import Image22 from "./Images/Img22.jpg";
import Image23 from "./Images/Img23.jpg";
import Image24 from "./Images/Img24.jpg";
import Image25 from "./Images/Img25.jpg";
import Image26 from "./Images/Img26.jpg";


const images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
  Image15,
  Image16,
  Image17,
  Image18,
  Image19,
  Image20,
  Image21,
  Image22,
  Image23,
  Image24,
  Image25,
  Image26,

];

const SampleGallary = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesToShow, setImagesToShow] = useState(5); // Default to 5

  const updateImagesToShow = () => {
    if (window.innerWidth <= 600) {
      setImagesToShow(3); // Show 3 images for mobile size
    } else {
      setImagesToShow(5); // Show 5 images for larger screens
    }
  };

  useEffect(() => {
    updateImagesToShow(); // Set initial images to show
    window.addEventListener("resize", updateImagesToShow); // Update on resize
    return () => window.removeEventListener("resize", updateImagesToShow);
  }, []);

  const nextImages = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + imagesToShow, images.length - imagesToShow)
    );
  };

  const prevImages = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - imagesToShow, 0));
  };

  return (
    <div className="gallery-container">
      <button
        className="Garrow left"
        onClick={prevImages}
        disabled={currentIndex === 0}
      >
        &lt;
      </button>
      {images
        .slice(currentIndex, currentIndex + imagesToShow)
        .map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Gallery ${currentIndex + index + 1}`}
            className="gallery-image"
            loading="lazy"
          />
        ))}
      <button
        className="Garrow right"
        onClick={nextImages}
        disabled={currentIndex + imagesToShow >= images.length}
      >
        &gt;
      </button>
    </div>
  );
};

export default SampleGallary;
