import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./Customer_Registraction_Form2.css";
import ToasterS from "./Toaster";
import ToasterF from "./ToasterF";
import api from "../../api";

const CustomerRegistractionForm2 = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    address: "",
    issue: "",
    therapy: "",
    gender: "",
    age: "",
    preferGender: "",
    appointment: "",
  });

  const [errors, setErrors] = useState({});
  const [tempCity, setTempCity] = useState("");
  const [tempPincode, setTempPincode] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const modalRef = useRef(null); // Create a ref for the modal
  const [showToastFail, setShowToastFail] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  // Validate each field as user types
  const validateField = (name, value) => {
    let tempErrors = { ...errors };

    switch (name) {
      case "name":
        if (!value) {
          tempErrors.name = "Name is required";
        } else if (!/^[A-Za-z\s]+$/.test(value)) { // Updated regex to allow spaces anywhere
          // Allow letters and single spaces between
          tempErrors.name = "Characters only.";
        } else {
          delete tempErrors[name];
        }
        break;
      case "contact":
        if (!value) {
          tempErrors.contact = "Contact number is required";
        } else if (!/^\d{10}$/.test(value)) {
          tempErrors.contact = "Contact number must be 10 digits";
        } else {
          delete tempErrors[name];
        }
        break;
      case "email":
        if (!value) {
          tempErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          tempErrors.email = "Email is not valid";
        } else {
          delete tempErrors[name];
        }
        break;
      case "gender":
        if (!value) {
          tempErrors.gender = "Gender is required";
        } else {
          delete tempErrors[name];
        }
        break;
      case "preferGender":
        if (!value) {
          tempErrors.preferGender = "Preferred Gender is required";
        } else {
          delete tempErrors[name];
        }
        break;
      case "address":
        if (!value) {
          tempErrors.address = "Address is required";
        } else {
          delete tempErrors[name];
        }
        break;
      case "pincode":
        if (!value) {
          tempErrors.pincode = "Pin Code is required";
        } else {
          delete tempErrors[name];
        }
        break;

      case "age":
        if (!value) {
          tempErrors.age = "Age is required";
        } else if (value < 0 || value > 120) {
          tempErrors.age = "age must be less than 120";
        } else if (isNaN(value)) {
          tempErrors.age = "number only.";
        } else {
          delete tempErrors[name];
        }
      default: 
      
    }

    setErrors(tempErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let sanitizedValue;

    // Check for the specific field to apply filtering
    if (name === "name") {
      sanitizedValue = value.replace(/[^a-zA-Z\s]/g, ""); // Allow letters and spaces
    } else if (name === "contact") {
      sanitizedValue = value.replace(/[^0-9]/g, ""); // For contact
    } else if (name === "age") {
      sanitizedValue = value.replace(/[^0-9]/g, ""); // For age
    } else {
      sanitizedValue = value; // For other fields, keep original value
    }

    setFormData({ ...formData, [name]: sanitizedValue });
    validateField(name, sanitizedValue); // Validate field as user types
  };

  // const handleCityChange = (selectedOption) => {
  //   setTempCity(selectedOption.value);

  //   // Update the address by including the selected city
  //   setFormData((prevFormData) => {
  //     const addressParts = prevFormData.address.split(",");
  //     // Use existing address's first part (without city/pincode) and update with new city and pincode
  //     const updatedAddress = `${addressParts[0] || ""}, ${
  //       selectedOption.value
  //     }, ${tempPincode}`.trim();
  //     return {
  //       ...prevFormData,
  //       address: updatedAddress,
  //       city: selectedOption.value,
  //     };
  //   });
  // };

  // const handleCityChange = (selectedOption) => {
  //   setTempCity(selectedOption.value);

  //   // Update the address by including the selected city
  //   setFormData((prevFormData) => {
  //     const addressParts = prevFormData.address.split(",");
  //     // Use existing address's first part (without city/pincode) and update with new city and pincode
  //     const updatedAddress = `${addressParts[0] || ""}, ${
  //       selectedOption.value
  //     }, ${tempPincode}`.trim();
  //     return {
  //       ...prevFormData,
  //       address: updatedAddress,
  //       city: selectedOption.value,
  //     };
  //   });
  // };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    setTempCity(selectedOption.value);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   address: `${prevFormData.address.split(",").slice(0, -1).join(",")}, ${selectedOption.value}, ${tempPincode}`.trim(),
    // }));
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setTempPincode(numericValue);
      // Also update the address with the new pincode
      // setFormData((prevFormData) => {
      //   const addressParts = prevFormData.address.split(",").filter(Boolean);
      //   // Rebuild the address to include the pincode
      //   const updatedAddress = `${
      //     addressParts[0] || ""
      //   }, ${tempCity}, ${numericValue}`.trim();
      //   return {
      //     ...prevFormData,
      //     address: updatedAddress,
      //   };
      // });
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    validateField(name, value); // Validate field as user types
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #80bdff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 150, // Set max height for the dropdown
      overflowY: "hidden",
      zIndex: 10, // Ensure the dropdown is above other elements
      fontFamily: "var(--firstChoiceFont)",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 150, // Set max height for the options list
      overflowY: "auto", // Disable scrollbar for options
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused
        ? "#f0f0f0"
        : isSelected
          ? "#e0e0e0"
          : "#ffffff",
      color: isSelected ? "#000" : "#333",
      cursor: "pointer",
    }),
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "Name is required";
    if (!formData.contact) {
      tempErrors.contact = "Contact number is required";
    } else if (!/^\d{10}$/.test(formData.contact)) {
      tempErrors.contact = "Contact number must be 10 digits";
    }
    if (!formData.email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email is not valid";
    }
    if (!formData.gender) tempErrors.gender = "Gender is required";
    if (!formData.preferGender)
      tempErrors.preferGender = "Prefer Gender is required";
    if (!formData.address) tempErrors.address = "Address is required";
    if (!tempCity) tempErrors.city = "City is required";
    if (!tempPincode) tempErrors.pincode = "Pin Code is required";

    if (!formData.age) {
      tempErrors.age = "Age is required";
    } else if (formData.age < 0 || formData.age > 120) {
      tempErrors.age = "Age must be between 0 and 120";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true); // Set loading to true when form submission starts

      if (tempCity && tempPincode) {
        const completeAddress = `${formData.address}, ${tempCity}, ${tempPincode}`;
        const finalFormData = {
          ...formData,
          address: completeAddress,
        };

       api
          .post(
            "/add_customer.php",
            finalFormData
          )
          .then((response) => {
             setToastMessage("Registration successful!");
            setShowToast(true);
            setTimeout(() => {
              handleClose();
            }, 2000);
            setTempCity("");
            setTempPincode("");
            setFormData({
              name: "",
              contact: "",
              email: "",
              address: "",
              issue: "",
              therapy: "",
              gender: "",
              preferGender: "",
              age: "",
            });
            setSelectedCity(null);
            setErrors({});
          })
          .catch((error) => {
            setToastMessage("Error submitting form data. Please try again.");
            setShowToastFail(true);
          })
          .finally(() => {
            setLoading(false); // Reset loading state after request completion
          });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          city: !tempCity ? "City is required" : "",
          pincode: !tempPincode ? "Pin Code is required" : "",
        }));
        setLoading(false); // Reset loading state if validation fails
      }
    }
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => setShowToast(false), 3000); // Toast duration
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        show
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose, show]);

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />

      <div className="modal-dialog" role="document" ref={modalRef}>
        {/* Modal content here */} {/* Attach ref here */}
        <div className="modal-dialog" role="document">
          <div className="modal-content customer-model-content">
            <div className="modal-header customer-model-header">
              <h5 className="modal-title customer-modal-title">Book Now</h5>
              <button
                type="button"
                className="close customer-close"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body customer-modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group customer-form-group">
                  <label
                    htmlFor="cname"
                    className="form_label customer-form_label"
                  >
                    Name:<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control customer-form-control"
                    id="cname"
                    placeholder="Full Name"
                    name="name"
                    pattern="^[A-Za-z\s]+$" // Updated pattern to allow letters and spaces
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <span className="customer-error">{errors.name}</span>
                  )}
                </div>

                <div className="form-group customer-form-group">
                  <div className="row">
                    <div className="col">
                      <label
                        htmlFor="contactnumber"
                        className="form_label customer-form_label"
                      >
                        Contact Number:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="tel"
                        className="form-control customer-form-control"
                        placeholder="Contact Number"
                        id="contactnumber"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        maxLength="10"
                        pattern="[0-9]{10}"
                        inputMode="numeric"
                      />
                      {errors.contact && (
                        <span className="customer-error">{errors.contact}</span>
                      )}
                    </div>
                    <div className="col">
                      <label
                        htmlFor="email-id"
                        className="form_label customer-form_label"
                      >
                        Email-id:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control customer-form-control"
                        placeholder="email-id"
                        id="email-id"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="customer-error">{errors.email}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group customer-form-group">
                  <label
                    htmlFor="inputAddress"
                    className="form_label customer-form_label"
                  >
                    Address:<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="form-control customer-form-control"
                    id="inputAddress"
                    placeholder="Address"
                    name="address"
                    onChange={handleChange}
                    value={formData.address}
                  />
                  {errors.address && (
                    <span className="customer-error">{errors.address}</span>
                  )}
                </div>

                <div className="form-group customer-form-group">
                  <div className="row">
                    <div className="col col-md-7 select-container">
                      <label
                        htmlFor="city"
                        className="form_label customer-form_label"
                      >
                        Area:<span style={{ color: "red" }}>*</span>
                      </label>

                      <Select
                        styles={customStyles}
                        className="react-select"
                        classNamePrefix="react-select"
                        options={[
                          { value: "Alandi", label: "Alandi" },
                          { value: "Aundh", label: "Aundh" },
                          { value: "Ambegaon", label: "Ambegaon" },
                          { value: "Baner Gaon", label: "Baner Gaon" },
                          { value: "Bavdhan Khurd", label: "Bavdhan Khurd" },
                          { value: "Bavdhan Budruk", label: "Bavdhan Budruk" },
                          { value: "Balewadi", label: "Balewadi" },
                          { value: "Bhugaon", label: "Bhugaon" },
                          { value: "Bhukum", label: "Bhukum" },
                          { value: "Dhankawadi", label: "Dhankawadi" },
                          { value: "Dehu", label: "Dehu" },
                          { value: "Dehu Road", label: "Dehu Road" },
                          { value: "Dhanori", label: "Dhanori" },
                          { value: "Dhayari", label: "Dhayari" },
                          { value: "Erandwane", label: "Erandwane" },
                          { value: "Fursungi", label: "Fursungi" },
                          { value: "Ghorpadi", label: "Ghorpadi" },
                          { value: "Hadapsar", label: "Hadapsar" },
                          { value: "Hingne Khurd", label: "Hingne Khurd" },
                          { value: "Hinjawadi", label: "Hinjawadi" },
                          // { value: "Indapur", label: "Indapur" },
                          { value: "Karve Nagar", label: "Karve Nagar" },
                          { value: "Kalas", label: "Kalas" },
                          { value: "Katraj", label: "Katraj" },
                          // { value: "Khadkale", label: "Khadkale" },
                          { value: "Khadki", label: "Khadki" },
                          { value: "Kharadi", label: "Kharadi" },
                          { value: "Kondhwa", label: "Kondhwa" },
                          { value: "Koregaon Park", label: "Koregaon Park" },
                          { value: "Kothrud", label: "Kothrud" },
                          { value: "Lohegaon", label: "Lohegaon" },
                          { value: "Lonavala", label: "Lonavala" },
                          { value: "Magarpatta", label: "Magarpatta" },
                          { value: "Manjri", label: "Manjri" },
                          { value: "Markal", label: "Markal" },
                          { value: "Mohammed Wadi", label: "Mohammed Wadi" },
                          { value: "Mundhwa", label: "Mundhwa" },
                          { value: "Nanded", label: "Nanded" },
                          {
                            value: "Parvati (Parvati Hill)",
                            label: "Parvati (Parvati Hill)",
                          },
                          { value: "Panmala", label: "Panmala" },
                          { value: "Pashan", label: "Pashan" },
                          { value: "Pirangut", label: "Pirangut" },
                          { value: "Phursungi", label: "Phursungi" },
                          { value: "Pimpri Colony", label: "Pimpri Colony" },
                          {
                            value: "Pimpri-Chinchwad",
                            label: "Pimpri-Chinchwad",
                          },
                          { value: "Pune", label: "Pune" },
                          // { value: "Sanaswadi", label: "Sanaswadi" },
                          // { value: "Saswad", label: "Saswad" },
                          // { value: "Shirur", label: "Shirur" },
                          { value: "Shivajinagar", label: "Shivajinagar" },
                          { value: "Shivane", label: "Shivane" },
                          { value: "Sus", label: "Sus" },
                          {
                            value: "Undri",
                            label: "Undri",
                          },
                          {
                            value: "Talegaon Dabhade",
                            label: "Talegaon Dabhade",
                          },
                          { value: "Talwade", label: "Talwade" },
                          { value: "Vadgaon", label: "Vadgaon" },
                          { value: "Vadgaon Budruk", label: "Vadgaon Budruk" },
                          { value: "Vadgaon Khurd", label: "Vadgaon Khurd" },
                          { value: "Vadgaon Sheri", label: "Vadgaon Sheri" },
                          { value: "Vishrantwadi", label: "Vishrantwadi" },
                          { value: "Vithalwadi", label: "Vithalwadi" },
                          { value: "Wagholi", label: "Wagholi" },
                          { value: "Wanwadi", label: "Wanwadi" },
                          { value: "Warje", label: "Warje" },
                          { value: "Yerwada", label: "Yerwada" },
                        ]}
                        value={selectedCity} // Use selectedCity here
                        onChange={handleCityChange}
                      // isClearable
                      // menuPortalTarget={document.body}
                      />

                      {errors.city && (
                        <span className="customer-error">{errors.city}</span>
                      )}
                    </div>
                    <div className="col col-md-5">
                      <label
                        htmlFor="pincode"
                        className="form_label customer-form_label"
                      >
                        Pin Code:<span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <input
                        type="number"
                        className="form-control customer-form-control"
                        placeholder="Pin Code"
                        id="pincode"
                        name="pincode"
                        onChange={handleChangeAddress}
                        value={tempPincode}
                      /> */}

                      <input
                        type="text"
                        className="form-control customer-form-control"
                        placeholder="Pin Code"
                        id="pincode"
                        name="pincode"
                        onChange={handleChangeAddress}
                        value={tempPincode}
                        maxLength="6"
                        pattern="[0-9]{6}"
                        inputMode="numeric"
                      />
                      {errors.pincode && (
                        <span className="customer-error">{errors.pincode}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group customer-form-group">
                  <label
                    htmlFor="Issue"
                    className="form_label customer-form_label"
                  >
                    Issue:
                  </label>
                  <input
                    type="text"
                    className="form-control customer-form-controll"
                    id="Issue"
                    placeholder="Describe your issue"
                    name="issue"
                    value={formData.issue}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group customer-form-group">
                  <label
                    htmlFor="therapy"
                    className="form_label customer-form_label"
                  >
                    Therapy:
                  </label>
                  <input
                    type="text"
                    className="form-control customer-form-control"
                    id="therapy"
                    placeholder="Describe therapy you want."
                    name="therapy"
                    value={formData.therapy}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group customer-form-group">
                  <div className="row">
                    <div className="col col-md-4">
                      <label
                        htmlFor="Gender"
                        className="form_label customer-form_label"
                      >
                        Gender:<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control customer-form-control"
                        id="Gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      {errors.gender && (
                        <span className="customer-error">{errors.gender}</span>
                      )}
                    </div>

                    <div className="col col-md-2">
                      <label
                        htmlFor="Age"
                        className="form_label customer-form_label"
                      >
                        Age:<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control customer-form-control"
                        id="Age"
                        name="age"
                        placeholder="Age"
                        maxLength="3"
                        // pattern="[0-9]{3}"
                        inputMode="numeric"
                        // inputMode="numeric"

                        value={formData.age}
                        onChange={handleChange}
                      ></input>
                      {errors.age && (
                        <span className="customer-error">{errors.age}</span>
                      )}
                    </div>

                    <div className="col col-md-6">
                      <label
                        htmlFor="preferGender"
                        className="form_label customer-form_label"
                      >
                        Prefer Gender:<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-control customer-form-control"
                        id="preferGender"
                        name="preferGender"
                        value={formData.preferGender}
                        onChange={handleChange}
                      >
                        <option value="">Preferred Gender</option>
                        <option value="male">Male Therapist</option>
                        <option value="female">Female Therapist</option>
                        <option value="any">Any</option>
                      </select>

                      {errors.preferGender && (
                        <span className="customer-error">
                          {errors.preferGender}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="customer-button-container">
                  {/* <button
                    type="submit"
                    className="btn btn-primary customer-btn"
                  >
                    Submit
                  </button> */}
                    <button
  type="submit"
  className="btn btn-primary customer-btn"
  disabled={loading} // Disable button while loading
  style={{ position: "relative", paddingLeft: "30px" }} // Add padding for text
>
  {loading ? "Loading..." : "Submit"}
</button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerRegistractionForm2;
