import React, { useState, useEffect } from "react";
import { Button, Alert, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { GetMassagerIdAction } from "../../Redux/Action/GetMassagerAction";
import Paginate from "../../Pagegnation/Pagination";
import ToasterS from "../../Customer Panel/Toaster";
import ToasterF from "../../Customer Panel/ToasterF";
import LoadingSpinner from "../../Loader/Loader";
import ConfirmBox from "../../ConfarmBox/ConfirmBox";
import "./Massager_CustomerList.css";
import api from "../../../api";

const MassagerCustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [massagerId, setMassagerId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showToastFail, setShowToastFail] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [currentAction, setCurrentAction] = useState({});

  const massagerEmail = useSelector((state) => state.MassagerValueGat.email);
  const dispatch = useDispatch();

  // Function to fetch customers
  const fetchCustomers = async (page = 1) => {
    setLoading(true);
    const payload = {
      role: "massager",
      email: massagerEmail,
      page: page,
      records_per_page: itemsPerPage,
    };
    try {
      const response = await api.post(
        "/CustomerMassagerPanel.php",
        payload
      );
      console.log(response.data);
      setMassagerId(response.data.massager_id);
      const filteredCustomers =
        response.data.data?.filter(
          (customer) =>
            customer.status !== "completed" && customer.status !== "rejected"
        ) || [];
      setCustomers(filteredCustomers);
      setTotalItems(response.data.totalRecords || 0);
    } catch (err) {
      setError("Failed to fetch data");
      setToastMessage("Failed to fetch data");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(currentPage);
  }, []);

  useEffect(() => {
    dispatch(GetMassagerIdAction(massagerId));
  }, [massagerId]);

  // Function to update customer status
  const handleStatus = (customerId, requestId, status) => {
    if (massagerId) {
      const payload = { requestId, status, customerId, massagerEmail };
      setLoadingStates((prev) => ({ ...prev, [customerId]: status }));

      api
        .post(
          "/update_request_status.php",
          payload
        )
        .then((response) => {
          const message =
            status === "completed"
              ? "Successfully Completed 👍"
              : status === "accepted"
              ? "Successfully Accepted 👍"
              : "Successfully Rejected ❌";

          setToastMessage(message);
          setShowToast(status === "accepted" || status === "completed");
          setShowToastFail(status === "rejected");
 
          setCustomers((prevCustomers) =>
            prevCustomers.filter((c) => c.id !== customerId)
          );
        })
        .catch((err) => {
          setToastMessage("Error updating status. Please try again.");
          setShowToastFail(true);
        })
        .finally(() => {
          setLoadingStates((prev) => ({ ...prev, [customerId]: null }));
          fetchCustomers()
        });
    } else {
      setToastMessage("Please select a massager before assigning.");
      setShowToastFail(true);
    }
  };

  // Function to handle confirmation of actions
  const handleConfirmAction = () => {
    handleStatus(
      currentAction.customerId,
      currentAction.requestId,
      currentAction.status
    );
    setShowConfirm(false);
  };

  // Function to request an action (accept/reject)
  const requestAction = (customerId, requestId, status) => {
    setCurrentAction({ customerId, requestId, status });
    setShowConfirm(true);
  };

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCustomers(page);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedDate}, ${formattedTime}`;
  };

  if (error) return <Alert variant="danger">{error}</Alert>;
  if (loading) return <LoadingSpinner />;

  return (
    <div className="massager-table-container">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      <h2>Customers List</h2>

      <Table
        responsive
        bordered
        hover
        className="massager-responsive-table mt-2"
      >
        <thead>
          <tr>
            <th >Sr.No</th>
            <th>Booking Time</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Issue</th>
            <th>Therapy</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Appointment</th>
            <th>Accept</th>
            <th>Reject</th>
            <th>Complete</th>
          </tr>
        </thead>
        <tbody>
          {customers.length > 0 ? (
            customers
              .slice()
              .sort((a, b) => new Date(b.time) - new Date(a.time))
              .map((customer, index) => (
                <tr key={customer.id}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>{formatDateTime(customer.requestTime)}</td>
                  <td>{customer.name}</td>
                  <td>{customer.contact}</td>
                  <td>{customer.email}</td>
                  <td>{customer.issue}</td>
                  <td>{customer.therapy}</td>
                  <td>{customer.gender}</td>
                  <td>{customer.address}</td>
                  <td>{customer.appointment}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() =>
                        handleStatus(
                          customer.id,
                          customer.requestId,
                          "accepted"
                        )
                      }
                      disabled={
                        customer.status === "accepted" ||
                        customer.status === "completed"
                      }
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {loadingStates[customer.id] === "accepted" && (
                        <div className="spinner-accept" />
                      )}
                      <span
                        style={{
                          marginLeft:
                            loadingStates[customer.id] === "accepted"
                              ? "0.5em"
                              : "0",
                        }}
                      >
                        {loadingStates[customer.id] === "accepted"
                          ? "Accepting..."
                          : "Accept"}
                      </span>
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() =>
                        requestAction(
                          customer.id,
                          customer.requestId,
                          "rejected"
                        )
                      }
                      disabled={
                        customer.status === "accepted" ||
                        customer.status === "completed"
                      }
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {loadingStates[customer.id] === "rejected" && (
                        <div className="spinner-reject" />
                      )}
                      <span
                        style={{
                          marginLeft:
                            loadingStates[customer.id] === "rejected"
                              ? "0.5em"
                              : "0",
                        }}
                      >
                        {loadingStates[customer.id] === "rejected"
                          ? "Rejecting..."
                          : "Reject"}
                      </span>
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() =>
                        handleStatus(
                          customer.id,
                          customer.requestId,
                          "completed"
                        )
                      }
                      disabled={customer.status === "completed" ||
                               customer.status === "pending"
                      }
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {loadingStates[customer.id] === "completed" && (
                        <div className="spinner-complete" />
                      )}
                      <span
                        className="completing-word"
                        style={{
                          marginLeft:
                            loadingStates[customer.id] === "completed"
                              ? "0.5em"
                              : "0",
                        }}
                      >
                        {loadingStates[customer.id] === "completed"
                          ? "Completing..."
                          : "Complete"}
                      </span>
                    </Button>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan="13" style={{ textAlign: "center" }}>
                No customers found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Paginate
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
      />

      <ConfirmBox
        show={showConfirm}
        message={`Are you sure you want to proceed with ${
          currentAction.status === "accepted" ? "accepting" : "rejecting"
        } this customer?`}
        onConfirm={handleConfirmAction}
        onCancel={() => setShowConfirm(false)}
      />
    </div>
  );
};

export default MassagerCustomerList;
