import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navpage from './Components/Navbar/Navbar';
import HomePage from './Components/Home Page/Home';
import AboutUs from './Components/About Us/AboutUs';
import TreatmentNav from './Components/Our Treatments/TreatmentNav';
import AdvanceMassageTherapy from './Components/Our Treatments/AdvanceMassageTherapy';
import NeuroTherapy from './Components/Our Treatments/NeuroTherapy';
import ChiropracticAdjustment from './Components/Our Treatments/ChiropracticAdjustment';
import CuppingTherapy from './Components/Our Treatments/CuppingTherapy';
import BoneAlignmentTherapy from './Components/Our Treatments/BoneAlignmentTherapy';
import BenefitsMassage from './Components/Benefits of Massage/BenefitsMassage';
import ContactUs from './Components/Contact Us/ContactUs';
import AdminPanel from './Components/Admin Panel/AdminPanel';
import Unauthorized from './Components/Login/Unauthorized';
import ProtectedRoute from './Components/Admin Panel/ProtectedRoute';
import MassagerPanel from './Components/Massager Panel/Massager';
import Footer from './Components/Footer/Footer';
// import CustomerAndMessengerTable from './Components/Massager Panel/AdminDisplayForm';
import './App.css';
import Certification from './Components/Our Certification/Certification';
import SidebarFooterIcon from './Components/Footer/IconSideBar/IconSideBar';
import CustomerRegistractionForm2 from './Components/Customer Panel/Customer_Registraction_Form2';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store,persistor} from './Components/Redux/store/store';
import PageNotFound from './Components/Page Not Found/PageNotFound';
import UnauthorizedUser from './Components/Unauthorized User/UnauthorizedUser';
import Waxing from './Components/Our Treatments/Waxing';



function App() {
  const location = useLocation();

  // Routes where Navpage and Footer should be hidden
  const hideNavbarFooterRoutes = [
    '/admin-panel', '/massager-panel', '/admin-panel/*', '/massager-panel/*', 
    '/admindashboard', '/customer-list', '/massager-list', '/add-video', '/report','/add-massager','/pending-order-report','/accepted-order-report'
  ];

  // Check if the current route matches any path where Nav and Footer should be hidden
  const shouldHideNavAndFooter = hideNavbarFooterRoutes.some(route => location.pathname.startsWith(route));

  // Check if current route belongs to admin or massager panel to avoid margin
  const noMarginRoutes = ['/admin-panel', '/massager-panel', '/admin-panel/*', '/massager-panel/*', 
    '/admindashboard', '/customer-list', '/massager-list', '/add-video', '/report','/add-massager','/pending-order-report','/accepted-order-report'];
  const shouldApplyMargin = !noMarginRoutes.some(route => location.pathname.startsWith(route));

  return (
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
    <div className="App">
      {/* Conditionally render Navpage based on the route */}
      {!shouldHideNavAndFooter && <Navpage />}
     
      {/* Apply content margin conditionally */}
      
      <div className={`content ${shouldApplyMargin ? 'apply-margin' : ''}`}>
        <Routes>
          {/* Default routes */}
          
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/treatments" element={<TreatmentNav />} />
          <Route path="/advance-massage-therapy" element={<AdvanceMassageTherapy />} />
          <Route path="/neuro-therapy" element={<NeuroTherapy />} />
          <Route path="/chiropractic-adjustment" element={<ChiropracticAdjustment />} />
          <Route path="/cupping-therapy" element={<CuppingTherapy />} />
          <Route path="/bone-alignment-therapy" element={<BoneAlignmentTherapy />} />
          <Route path="/Waxing" element={<Waxing />} />
          <Route path="/benefits" element={<BenefitsMassage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/customerRegistration" element={<CustomerRegistractionForm2 />} />
          <Route path="/certification" element={<Certification/>} />
          <Route path='/unauthorized' element={<UnauthorizedUser/>}/>
          <Route path='/*' element={<PageNotFound/>}/>
          
       

          {/* Admin-specific route */}
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path="/admin-panel/*" element={<AdminPanel />} />
          </Route>

          {/* Massager-specific route */}
          <Route element={<ProtectedRoute allowedRoles={['massager']} />}>
            <Route path="/massager-panel/*" element={<MassagerPanel />} />
          </Route>

          {/* Unauthorized page */}
          <Route path="/unauthorized" element={<Unauthorized />} />
        
        </Routes>
      </div>
      
      {!shouldHideNavAndFooter && <SidebarFooterIcon />}

    
      {/* Conditionally render footer based on the route */}
      {!shouldHideNavAndFooter && <Footer />}
       
    </div>
    </PersistGate>
    </Provider>
  );
}

export default App;
