import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import loginlogo from "./login-logo2.png";
import loginbg from "./bglogin.png";
import ForgetPassword from "./ForgetPassword";
import "./login.css";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GatMassagerEmailAction } from "../Redux/Action/GetMassagerAction";
import ToasterS from "../Customer Panel/Toaster";
import ToasterF from "../Customer Panel/ToasterF";
import api from "../../api";

// Validation schema for Formik
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  password: Yup.string()
    .min(4, "Password must be 4 characters at minimum")
    .required("Password is required"),
});

const Login = ({ show, handleClose, role }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [massagerEmail, setMassagerEmail] = useState("");
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [showToastFail, setShowToastFail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch(GatMassagerEmailAction(massagerEmail));
  }, [massagerEmail]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => setShowToast(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const hashPassword = (password) => {
    return CryptoJS.SHA256(password).toString();
  };

  const startLogoutTimer = () => {
    setTimeout(() => {
      handleLogout();
    }, 24 * 60 * 60 * 1000);
  };

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userRole");
    setToastMessage("Session expired. You have been logged out.");
    setShowToastFail(true);
    navigate("/#login");
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      <Modal.Header closeButton className="custom-modal-header">
        <img src={loginlogo} alt="login-logo" className="login-logo me-2" />
        <Modal.Title className="modaltitle me-5">Login as {role.charAt(0).toUpperCase() + role.slice(1)}</Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          backgroundImage: `url(${loginbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        {!showForgotPassword ? (
          <div className="container mb-2">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={LoginSchema}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);

                if (role === "massager") {
                  setMassagerEmail(values.email);
                }

                const hashedPassword = hashPassword(values.password);

                api
                  .post("/ApiLogin.php", {
                    email: values.email,
                    password: hashedPassword,
                    role,
                  })
                  .then((response) => {
                    if (response.data.status === "success") {
                      localStorage.setItem("isAuthenticated", true);
                      localStorage.setItem("userRole", response.data.role);
                      setToastMessage("Login successful!");
                      setShowToast(true);
                      startLogoutTimer();
                      navigate(
                        role === "admin" ? "/admin-panel" : "/massager-panel"
                      );
                    } else {
                      setToastMessage(
                        "Login failed. Please check your credentials."
                      );
                      setShowToastFail(true);
                    }
                  })
                  .catch((error) => {
                    setToastMessage("Failed to login. Please try again.");
                    setShowToastFail(true);
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting, touched, errors }) => (
                <Form className="ABCD">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      autoComplete="off"
                      className={`mt-1 form-control ${touched.email && errors.email ? "is-invalid" : ""
                        }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="mt-2">
                      Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      className={`form-control ${touched.password && errors.password ? "is-invalid" : ""
                        }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>
                  {/* <button
                    type="submit"
                    className="btn btn-primary btn-block mt-3 position-relative"
                    disabled={isSubmitting || isLoading}
                  >
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span className="ms-2">Loading...</span>
                      </>
                    ) : isSubmitting ? (
                      "Submitting..."
                    ) : (
                      "Login"
                    )}
                  </button> */}
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-3 "
                    disabled={isSubmitting || isLoading}
                  >
                    {isLoading && (
                      <span className="custom-spinner" role="status" aria-hidden="true"></span>
                    )}
                    <span className={isLoading ? "text-loading" : ""}>
                      {isLoading ? "Loading..." : isSubmitting ? "Submitting..." : "Login"}
                    </span>
                  </button>



                  <button
                    type="button"
                    className="btn btn-link mt-2"
                    onClick={() => setShowForgotPassword(true)}
                  >
                    Forget Password?
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <ForgetPassword onBack={() => setShowForgotPassword(false)} role={role} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Login;
