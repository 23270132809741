import Carousel from "react-bootstrap/Carousel";
import IMG1 from "./imgthree.jpg";
import IMG2 from "./imgone.jpg";
import IMG3 from "./img1.jpg";
import IMG4 from "./imgtwo.jpg";
import "./Home.css";

const HomePage = () => {
  return (
    <div className="carousel-container">
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img className="d-block w-100" src={IMG1} alt="Tranquility" />
          <Carousel.Caption>
            <h5 className="display-4 fw-bold text-muted bg-transpernt p-3 rounded HPHeader">
              Discover Tranquility
            </h5>
            <p className="lead fw-normal text-white HPPara">
              Experience the ultimate relaxation with our exclusive spa
              treatments. Escape the daily grind and rejuvenate your senses.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={IMG2} alt="Luxury" />
          <Carousel.Caption>
            <h5 className="display-4 fw-bold text-muted bg-transpernt p-3 rounded HPHeader">
              Unwind in Luxury
            </h5>
            <p className="lead fw-normal text-white HPPara">
              Indulge in our luxurious amenities and personalized services.
              Perfect for a serene getaway or a special celebration.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={IMG3} alt="Couple's Retreat" />
          <Carousel.Caption>
            <h5 className="display-4 fw-bold text-muted bg-transpernt p-3 rounded HPHeader">
              Couple's Retreat
            </h5>
            <p className="lead fw-normal text-white HPPara">
              Share a moment of peace with your loved one in our romantic
              couple's massage package. A perfect blend of relaxation and
              intimacy.
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={IMG4} alt="Elegant Atmosphere" />
          <Carousel.Caption>
            <h5 className="display-5 fw-bold text-warning bg-transpernt p-2 rounded HPHeader">
              Elegant Atmosphere
            </h5>
            <p className="lead fw-normal text-white HPPara">
              Step into our beautifully designed space where elegance meets
              comfort.
            </p>
            <p className="lead fw-normal text-white HPPara">
              Every detail is curated to offer you an unforgettable experience.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default HomePage;
