import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PageNotFound.css';
import notFoundImage from './PageNotFound.jpg';
import { Image, Spinner } from "react-bootstrap";
import HomePageIcon from "./HomePageIcon.jpg";

const LoadingSpinner = () => {
    return (
        <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    );
};

const PageNotFound = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleGoHome = () => {
        navigate('/');
    };

    useEffect(() => {
        // Simulate a network check
        const timer = setTimeout(() => {
            setIsLoading(false); // Set loading to false after 2 seconds
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="not-found-container">
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <img src={notFoundImage} alt="Page Not Found" className="not-found-image" />
                    <h1 className="not-found-title">404 Page Not Found</h1>
                    <p className="not-found-message">Oops! The page you're looking for doesn't exist.</p>
                    <Image
                        src={HomePageIcon}
                        alt="Go Home"
                        className='HomePageIcon'
                        onClick={handleGoHome}
                    />
                </>
            )}
        </div>
    );
};

export default PageNotFound;
