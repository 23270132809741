import React from 'react';
import SidebarMassager from './SidebarMassager';
import { Routes, Route } from 'react-router-dom';
import MassagerDashboard from './Massager_Component/Massager_DashBoard';
import MassagerCustomerList from './Massager_Component/Massager_CustomerList';
// import MassagerReport from './Massager_Component/Massager_Report';
import './Massager.css';
import MassagerComponentOrder from './Massager_Component/MassagerCompleteOrder';
import MassagerAcceptOrder from './Massager_Component/MassagerAcceptOrder';
import MassagerTotalOrder from './Massager_Component/MassagerTotalOrder';
import { FaSpa } from "react-icons/fa";


const Massager = () => {
    return (
        <div className="massager-panel">
            <SidebarMassager />
            <div className="Massager-content">
                <main>

                    <div className="marquee-container">
                        <h4 className='text-end marquee-text'>
                           <FaSpa/> &nbsp;
                            Welcome to Massager Panel &nbsp;
                          <FaSpa/>
                        </h4>
                    </div>

                    <Routes>
                        <Route index element={<MassagerDashboard />} />
                        <Route path="massagerdashboard" element={<MassagerDashboard />} />
                        <Route path="massagercustomerlist" element={<MassagerCustomerList />} />
                        {/* <Route path="MassagerReport" element={<MassagerReport/>}/>  */}
                        <Route path='massagercomponentorder' element={<MassagerComponentOrder />} />
                        <Route path='massageracceptorder' element={<MassagerAcceptOrder />} />
                        <Route path='massagertotalorder' element={<MassagerTotalOrder />} />
                    </Routes>
                </main>
            </div>
        </div>
    );
};

export default Massager;
