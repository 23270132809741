import React, { useEffect } from "react";
import "./Toaster.css"; // Custom CSS for animation
import Toast from "react-bootstrap/Toast";

const ToasterF = ({ show, message, onClose }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose(); // Call parent component's function to hide toast
      }, 5000); // Wait for 5 seconds
      return () => clearTimeout(timer);
    }
  }, [show, onClose]); // Include show and onClose in the dependency array

  return (
    <>
      {show && (
        <Toast
          className={`d-inline-block m-1 position-fixed top-0 end-0 p-3`}
          bg="danger"
          onClose={onClose}
          show={show}
        >
          <Toast.Header>
            <strong className="me-auto FailToasterHeader">Notification</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body className="FailToasterBody text-white">
            {message}
          </Toast.Body>
        </Toast>
      )}
    </>
  );
};

export default ToasterF;
