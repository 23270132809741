import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "./customerlist.css";
import ToasterS from "../../Customer Panel/Toaster";
import ToasterF from "../../Customer Panel/ToasterF";
import Paginate_Admin from "./PaginationFor_Admin_report";
import LoadingSpinner from "../../Loader/Loader";
import ConfirmBox from "../../ConfarmBox/ConfirmBox";
import api from "../../../api";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [massagers, setMassagers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null); // To track the current customer in the modal
  const [filters, setFilters] = useState({ address: "", preferredGender: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [searchFilter, setSearchFilter] = useState({ address: "", name: "" });
  const [showEditModal, setShowEditModal] = useState(false); // For edit modal
  const [errorsModel, seterrorsModel] = useState({});
  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const [showToastFail, setShowToastFail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Items per page
  const [isUpdating, setIsUpdating] = useState(false);//Update button loadder
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [massagerToDelete, setMassagerToDelete] = useState();

  // const [loadingAssign, setLoadingAssign] = useState(false);
  const [loadingMassagerId, setLoadingMassagerId] = useState(null);
  

  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    address: "",
    gender: "",
    prefgender: "",
    age: "",
    issue: "",
    therapy: "",
    appointment: "",
    adate: "",
    atime: "",
  });

  const indexOfLastCustomer = currentPage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  const currentCustomers = customers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  useEffect(() => {
    fetchData();
  }, []);
 
  const fetchData = async (page=1) => {
   
    try {
      const response = await api.post(
        "/cust_mas_requests_view.php"
      );
      const assignedMassagerResponse = await api.get(
        "/getAssignedMassager.php"
      );

      const data = response.data.data;

      const assignedMassagers = assignedMassagerResponse.data.data;

      // Filter customers based on 'type' = 'Customer'
      const filteredCustomers = data.filter((item) => item.type === "Customer");

      // Filter massagers based on 'type' = 'Massager'
      const filteredMassagers = data.filter((item) => item.type === "Massager");

      // Filter requests based on 'type' = 'Request'
      const filteredRequests = data.filter((item) => item.type === "Request");

      // Create a mapping of customerId to assigned massagerId
      const massagerMapping = {};
      assignedMassagers.forEach((assignment) => {
        massagerMapping[assignment.customerId] = assignment.massagerId;
      });


      // Create a mapping of massagerId to massager name
      const massagerNameMapping = {};
      filteredMassagers.forEach((massager) => {
        massagerNameMapping[massager.id] = massager.name;
      });

      // Map through customers and add request_status and assigned massager name
      const updatedCustomers = filteredCustomers
        .map((customer) => {
          // Find the corresponding request for the customer
          const matchedRequest = filteredRequests.find(
            (request) => request.id === customer.id // Match by customer_id
          );

          // Get the assigned massager ID if it exists
          const assignedMassagerId = massagerMapping[customer.id];

          // Get the massager name if the ID exists in the mapping
          const assignedMassagerName = massagerNameMapping[assignedMassagerId];

          // Add request_status and assigned massager name if a matching request is found
          return {
            ...customer,
            status: matchedRequest ? matchedRequest.request_status : "Pending", // Default to 'Pending'
            massagerName: assignedMassagerName || "Assign", // Add massager name or "Assign" if not assigned
          };
        })
        // Exclude customers whose status is 'Completed'
        .filter((customer) => customer.status !== "completed");

      // Set the state with updated customers and filtered massagers
      // setCustomers(updatedCustomers);
      setCustomers([...updatedCustomers].reverse());
        setMassagers(filteredMassagers);
      setLoading(false);
    } catch (err) {
      // alert("Failed to fetch data. Please try again later.");
      setToastMessage("Failed to fetch data. Please try again later.");
      setShowToastFail(true);
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page); // Update the current page
  };
  
  // Function to handle opening the modal and setting filters
  const handleOpenModal = (customer) => {
    setSelectedCustomer(customer);
    setFilters({ ...filters, preferredGender: customer.preferred_gender }); // Apply preferred gender filter by default
    // setFilteredMassagers(
    //   massagers.filter(massager => massager.preferred_gender === customer.preferred_gender)
    // );

    setShowModal(true);
  };


  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCustomer(null);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // Function to filter massagers based on filters
  const applyFilters = async (address, gender) => {
    const filaddress = { address: address, gender: gender };
   
    try {
      const response = await api.post(
        "/search_massagers.php",
        filaddress
      );
      const data = response.data.data;

      if (response.data.status === "success") {
        setMassagers([]); // Clear old data
        setMassagers(data); // Set new data
        // Reset filters
      } else {
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
      setLoading(false);
    }
  };

  const clearFilters = async () => {
    try {
      const response = await api.get(
        "/cust_mas_requests_view.php"
      );
      const data = response.data.data;
      // Filter massagers based on 'type' = 'Massager'
      const filteredMassagers = data.filter((item) => item.type === "Massager");
      setMassagers(filteredMassagers);
      setFilters({ address: "", preferredGender: "" });
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch data. Please try again later.");
      setLoading(false);
    }
  };

  const applyFilteronCustomerList = async (name, address) => {
    try {
      let nameResponse = [];
      let addressResponse = [];
  
      if (name && address) {
        const nameFilter = { name: name };
        const addressFilter = { address: address };
  
        const [nameData, addressData] = await Promise.all([
          api.post("/cust_name_filter.php", nameFilter),
          api.post("/cust_add_filter.php", addressFilter),
        ]);
  
        nameResponse = nameData.data.data;
        addressResponse = addressData.data.data;
  
        const filteredData = nameResponse.filter((customer) =>
          addressResponse.some((addr) => addr.id === customer.id)
        );
  
        const assignedMassagerResponse = await api.get("/getAssignedMassager.php");
        const assignedMassagers = assignedMassagerResponse.data.data;
  
        const massagerMapping = {};
        assignedMassagers.forEach((assignment) => {
          massagerMapping[assignment.customerId] = assignment.massagerId;
        });
  
        const massagerNameMapping = {};
        massagers.forEach((massager) => {
          massagerNameMapping[massager.id] = massager.name;
        });
  
        const finalData = filteredData
          .map((customer) => {
            const assignedMassagerId = massagerMapping[customer.id];
            const assignedMassagerName = massagerNameMapping[assignedMassagerId];
  
            return {
              ...customer,
              status: customer.status || "Pending",
              massagerName: assignedMassagerName || "Assign",
            };
          })
          .filter((customer) => customer.status !== "completed"); // Hide 'completed' customers
  
        if (finalData.length > 0) {
          setCustomers([...finalData].reverse());
        } else {
          setToastMessage("No results found for the given name and address.");
          setShowToastFail(true);
        }
      } else if (address) {
        const addressFilter = { address: address };
        const response = await api.post("/cust_add_filter.php", addressFilter);
        addressResponse = response.data.data;
  
        const assignedMassagerResponse = await api.get("/getAssignedMassager.php");
        const assignedMassagers = assignedMassagerResponse.data.data;
  
        const massagerMapping = {};
        assignedMassagers.forEach((assignment) => {
          massagerMapping[assignment.customerId] = assignment.massagerId;
        });
  
        const massagerNameMapping = {};
        massagers.forEach((massager) => {
          massagerNameMapping[massager.id] = massager.name;
        });
  
        const finalAddressData = addressResponse
          .map((customer) => {
            const assignedMassagerId = massagerMapping[customer.id];
            const assignedMassagerName = massagerNameMapping[assignedMassagerId];
  
            return {
              ...customer,
              status: customer.status || "Pending",
              massagerName: assignedMassagerName || "Assign",
            };
          })
          .filter((customer) => customer.status !== "completed"); // Hide 'completed' customers
  
        if (response.data.status === "success") {
          setCustomers([...finalAddressData].reverse());
        } else {
          setToastMessage("No results found for the given address.");
          setShowToastFail(true);
        }
      } else if (name) {
        const nameFilter = { name: name };
        const response = await api.post("/cust_name_filter.php", nameFilter);
        nameResponse = response.data.data;
  
        const assignedMassagerResponse = await api.get("/getAssignedMassager.php");
        const assignedMassagers = assignedMassagerResponse.data.data;
  
        const massagerMapping = {};
        assignedMassagers.forEach((assignment) => {
          massagerMapping[assignment.customerId] = assignment.massagerId;
        });
  
        const massagerNameMapping = {};
        massagers.forEach((massager) => {
          massagerNameMapping[massager.id] = massager.name;
        });
  
        const finalNameData = nameResponse
          .map((customer) => {
            const assignedMassagerId = massagerMapping[customer.id];
            const assignedMassagerName = massagerNameMapping[assignedMassagerId];
  
            return {
              ...customer,
              status: customer.status || "Pending",
              massagerName: assignedMassagerName || "Assign",
            };
          })
          .filter((customer) => customer.status !== "completed"); // Hide 'completed' customers
  
        if (response.data.status === "success") {
          setCustomers([...finalNameData].reverse());
        } else {
          setToastMessage("No results found for the given name.");
          setShowToastFail(true);
        }
      }
    } catch (err) {
      setToastMessage("Failed to fetch data. Please try again later.");
      setShowToastFail(true);
      setLoading(false);
    }
  };
  

  const clearSearchFilters = async () => {
    try {
      const response = await api.get(
        "/cust_mas_requests_view.php"
      );
      const assignedMassagerResponse = await api.get(
        "/getAssignedMassager.php"
      );

      const data = response.data.data;
      const assignedMassagers = assignedMassagerResponse.data.data;

      // Filter customers based on 'type' = 'Customer'
      const filteredCustomers = data.filter((item) => item.type === "Customer");

      // Filter massagers based on 'type' = 'Massager'
      const filteredMassagers = data.filter((item) => item.type === "Massager");

      // Filter requests based on 'type' = 'Request'
      const filteredRequests = data.filter((item) => item.type === "Request");

      // Create a mapping of customerId to assigned massagerId
      const massagerMapping = {};
      assignedMassagers.forEach((assignment) => {
        massagerMapping[assignment.customerId] = assignment.massagerId;
      });

      // Create a mapping of massagerId to massager name
      const massagerNameMapping = {};
      filteredMassagers.forEach((massager) => {
        massagerNameMapping[massager.id] = massager.name;
      });

      // Map through customers and add request_status and assigned massager name
      const updatedCustomers = filteredCustomers
        .map((customer) => {
          // Find the corresponding request for the customer
          const matchedRequest = filteredRequests.find(
            (request) => request.id === customer.id // Match by customer_id
          );

          // Get the assigned massager ID if it exists
          const assignedMassagerId = massagerMapping[customer.id];

          // Get the massager name if the ID exists in the mapping
          const assignedMassagerName = massagerNameMapping[assignedMassagerId];

          // Add request_status and assigned massager name if a matching request is found
          return {
            ...customer,
            status: matchedRequest ? matchedRequest.request_status : "Pending", // Default to 'Pending'
            massagerName: assignedMassagerName || "Assign", // Add massager name or "Assign" if not assigned
          };
        })
        // Exclude customers whose status is 'Completed'
        .filter((customer) => customer.status !== "completed");

      // Set the state with updated customers and reset search filters
      setCustomers([...updatedCustomers].reverse());
      setSearchFilter({ name: "", address: "" });
      setLoading(false);
    } catch (err) {
      // alert("Failed to fetch data. Please try again later.");
      setToastMessage("Failed to fetch data. Please try again later.");
      setShowToastFail(true);
      setLoading(false);
    }
  };
  const handleAssign = (massagerId) => {
    if (!selectedCustomer || !massagerId) {
      setToastMessage("Please select a massager to assign.");
      setShowToastFail(true);
      return;
    }

    const assignmentData = {
      massager_id: massagerId,
      customer_id: selectedCustomer.id,
    };

    setLoadingMassagerId(massagerId); // Set loading for the specific massager

    api
      .post("/send_request.php", assignmentData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        fetchData();
        setToastMessage(`Massager assigned successfully to Customer`);
        setShowToast(true);
        handleCloseModal();
      })
      .catch((error) => {
        setToastMessage("Failed to assign massager. Please try again.");
        setShowToastFail(true);
      })
      .finally(() => {
        setLoadingMassagerId(null); // Reset loading state
      });
  };
  
  
  const handleEdit = (customerid) => {
    const customerToEdit = customers.find(
      (customer) => customer.id === customerid
    );
    if (customerToEdit) {
      setSelectedCustomer(customerToEdit);
      setFormData({
        name: customerToEdit.name,
        contact: customerToEdit.contact,
        email: customerToEdit.email,
        address: customerToEdit.address,
        prefgender: customerToEdit.prefgender,
        issue: customerToEdit.issue,
        therapy: customerToEdit.therapy,
        appointment: customerToEdit.appointment,
        adate: customerToEdit.appointment
          ? customerToEdit.appointment.split(" ")[0]
          : "",
        atime: customerToEdit.appointment
          ? customerToEdit.appointment.split(" ")[1]
          : "",
        age: customerToEdit.age,
      });
      setShowEditModal(true);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle closing the edit modal
  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedCustomer(null);
  };

  // Handle updating customer information
  const handleUpdate = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name field can't be empty.";
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
    if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Contact number validation
    const phonePattern = /^[0-9]{10}$/; // Pattern for 10-digit phone number
    if (!phonePattern.test(formData.contact)) {
      newErrors.contact = "Please enter a valid 10-digit contact number.";
    }

    if (Object.keys(newErrors).length > 0) {
      seterrorsModel(newErrors);
      return; // Exit if there are errors
    }

    const updatedCustomer = {
      ...formData,
      c_id: selectedCustomer.id, // Use selected customer ID
      appointment: `${formData.adate} ${formData.atime}:00`, // Combine date and time into a timestamp string
    };

    setIsUpdating(true); // Start loading

    api
      .post(
        "/update_customer.php",
        updatedCustomer,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setIsUpdating(false); // Stop loading

        if (response.data.status === "success") {
          setToastMessage("Customer information updated successfully!");
          setShowToast(true);

          // Update the customers list locally without reloading the page
          const updatedCustomers = customers.map((cust) =>
            cust.id === selectedCustomer.id
              ? { ...cust, ...updatedCustomer }
              : cust
          );

          setCustomers([...updatedCustomers].reverse());
          handleCloseEditModal();
        } else {
          setToastMessage("Failed to update customer. Please try again.");
          setShowToastFail(true);
        }
      })
      .catch((error) => {
        setIsUpdating(false); // Stop loading on error
        setToastMessage("Failed to update customer. Please try again.");
        setShowToastFail(true);
      });
  };
  

  const handleDelete = (massager) => {
    setMassagerToDelete(massager); // Store massager ID to be deleted
    setShowConfirmBox(true); // Show confirmation box
  };

  const confirmDelete = async (customerId) => {
    const customer_id = { customer_id: massagerToDelete};

    try {
      const response = await api.post(
        `/delete_customer.php`,
        customer_id
      );

      if (response.data.status === "customer deleted") {
        // alert(`Customer with ID: ${customerId} deleted successfully`);
        setToastMessage(`Customer deleted successfully`);
        setShowToast(true);

        setCustomers(
          customers.filter((customer) => customer.id !== customerId)
        );
      } else {
        // alert("Failed to delete customer. Please try again.");
        setToastMessage("Failed to delete customer. Please try again.");
        setShowToastFail(true);
      }
    } catch (error) {
      setToastMessage("Failed to delete customer. Please try again.");
      setShowToastFail(true);
    }finally {
      setShowConfirmBox(false); // Hide confirmation box
      setMassagerToDelete(null); // Clear the selected massager
      fetchData()
    }

  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Format the date part (DD/MMM/YY)
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short", // Example: 'Oct' for October
      year: "2-digit",
    });

    // Format the time part (HH:MM AM/PM)
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // 12-hour format with AM/PM
    });

    return `${formattedDate}, ${formattedTime}`;
  };

  // if (loading) return <p>Loading...</p>;
  if (loading) {
    return (
      <div className="spinner-container50">
       <LoadingSpinner/>
      </div>
    );
  }
  if (error) return <p className="text-danger">{error}</p>;

  return (
    <div className="table-container">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      <h2>Customers List</h2>

      {/* Search bars */}
      <div className="row mb-3 p-2 justify-content-end ">
        <div className="col-md-2 col-sm-6 col-12">
          <Form.Group controlId="searchName">
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={searchFilter.name}
              className="ABCS"
              onChange={(e) =>
                setSearchFilter({ ...searchFilter, name: e.target.value })
              }
            />
          </Form.Group>
        </div>
        <div className="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0">
          <Form.Group controlId="searchAddress">
            <Form.Control
              type="text"
              placeholder="Enter address"
              value={searchFilter.address}
              className="ABCS"
              onChange={(e) =>
                setSearchFilter({ ...searchFilter, address: e.target.value })
              }
            />
          </Form.Group>
        </div>

        <div className="col-md-2 col-sm-6 col-12 mt-2 mt-md-0">
          <Button
            variant="primary"
            className="w-100 ABCS" // Ensures button takes full width on smaller screens
            onClick={() =>
              applyFilteronCustomerList(searchFilter.name, searchFilter.address)
            }
          >
            Apply Filters
          </Button>
        </div>

        <div className="col-md-2 col-sm-6 col-12 mt-2 mt-md-0">
          <Button
            variant="primary"
            className="w-100 ABCS " // Ensures button takes full width on smaller screens
            onClick={() => clearSearchFilters()}
          >
            Clear Filters
          </Button>
        </div>
      </div>
      {/* Search bar end */}
      <table responsive bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>Sr.No.</th>
            <th> Booking Date</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Email </th>
            <th>Address</th>
            <th>Gender</th>
            <th>Age</th>
            <th>Pref Gender</th>
            <th>Issue</th>
            <th>Therapy</th>
            <th>Appointment</th>
            <th>Status</th>
            <th>Massager</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
          {currentCustomers
            .slice()
            .sort((a, b) => new Date(b.time) - new Date(a.time)) 
            .map((customer, index) => (
              <tr key={customer.id}>
                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                <td>{formatDateTime(customer.time)}</td>
                <td>{customer.name}</td>
                <td>{customer.contact}</td>
                <td>{customer.email}</td>
                <td>{customer.address}</td>
                <td>{customer.gender}</td>
                <td>{customer.age}</td>
                <td>{customer.prefgender}</td>
                <td className="issue-column">{customer.issue}</td>
                <td>{customer.therapy}</td>
                <td>{customer.appointment}</td>
                <td>
                  <span
                    className={`badge ${customer.status === 'pending'
                      ? 'bg-warning text-dark'  // Yellow for pending
                      : customer.status === 'rejected'
                        ? 'bg-danger'  // Red for rejected
                        : customer.status === 'accepted'
                          ? 'bg-success'  // Green for accepted
                          : 'bg-secondary'
                      }`}
                  >
                    {customer.status.charAt(0).toUpperCase() +
                      customer.status.slice(1)}
                  </span>
                </td>
                <td>
                  <Button
                    variant="primary"
                    onClick={() => handleOpenModal(customer)}
                    disabled={customer.status === "accepted"}
                  >
                    {customer.massagerName}
                  </Button>
                </td>
                <td>
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="action-icon hand-action-icon"
                    onClick={() => handleEdit(customer.id)}
                  />
                  &nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="action-icon hand-action-icon"
                    onClick={() => handleDelete(customer.id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>

      </table>

      {/* Modal for assigning massagers */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Assign Massager for {selectedCustomer?.customer_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <Form.Group controlId="filterAddress" className="col-md-6">
                <Form.Label>Filter by Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter address"
                  name="address"
                  value={filters.address}
                  onChange={handleFilterChange}
                />
              </Form.Group>

              <Form.Group controlId="filterGender" className="col-md-6">
                <Form.Label>Filter by Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="preferredGender"
                  value={filters.preferredGender}
                  onChange={handleFilterChange}
                >
                  <option value="all">All Genders</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Control>
              </Form.Group>
            </div>
            <div className="row">
              <div className="col-md-4">
                <Button
                  variant="primary"
                  onClick={() =>
                    applyFilters(filters.address, filters.preferredGender)
                  }
                  className="mt-3"
                >
                  Apply Filters
                </Button>
              </div>
              <div className="col-md-4">
                <Button
                  variant="primary"
                  onClick={() => clearFilters()}
                  className="mt-3"
                >
                  Clear Filters
                </Button>
              </div>
            </div>
            {/* Display Massagers in a table */}
            <Table responsive bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Gender</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(massagers) &&
                  massagers.map((massager) => (
                    <tr key={massager.massager_id}>
                      <td>{massager.massager_name}</td>
                      <td>{massager.massager_address}</td>
                      <td>{massager.massager_gender}</td>
                      <td>
                        {/* <Button
                          variant="success"
                          onClick={() => handleAssign(massager.massager_id)}
                        >
                          Assign
                        </Button> */}

                        <Button
                           variant="success"
                           onClick={() => handleAssign(massager.massager_id)}
                           disabled={loadingMassagerId !== null} // Disable all buttons if any are loading
                          className="custom-assign-button d-flex align-items-center" // Use new custom class
                        >
                        {loadingMassagerId === massager.massager_id && (
                        <div className="custom-spinner" />
                        )}
                        <span className={loadingMassagerId === massager.massager_id ? "custom-text-loading ms-2" : ""}>
                        {loadingMassagerId === massager.massager_id ? "Assigning..." : "Assign"}
                       </span>
                      </Button>


                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for editing customer details */}
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {" "}
            Edit Customer Details
          </Modal.Title>
        </Modal.Header>
        <div className="modal-body customer-list-modal-body">
          <form className="customer-modal-scrollable">
            <div className="form-group customer-list-form-group">
              <label
                htmlFor="cname"
                className="form_label customer-list-form_label"
              >
                Name:<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="form-control customer-form-control"
                id="cname"
                placeholder="Full Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              {errorsModel.name && (
                <span className="customer-error">{errorsModel.name}</span>
              )}
            </div>

            <div className="form-group customer-list-form-group">
              <div className="row">
                <div className="col">
                  <label
                    htmlFor="contactnumber"
                    className="form_label customer-list-form_label"
                  >
                    Contact Number:<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    className="form-control customer-form-control"
                    placeholder="Contact Number"
                    id="contactnumber"
                    name="contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                    maxLength="10"
                    pattern="[0-9]{10}"
                    inputMode="numeric"
                  />
                  {errorsModel.contact && (
                    <span className="customer-error">
                      {errorsModel.contact}
                    </span>
                  )}
                </div>
                <div className="col">
                  <label
                    htmlFor="email-id"
                    className="form_label customer-list-form_label"
                  >
                    Email-id:<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control customer-form-control"
                    placeholder="email-id"
                    id="email-id"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errorsModel.email && (
                    <span className="customer-error">{errorsModel.email}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group customer-list-form-group">
              <label
                htmlFor="inputAddress"
                className="form_label customer-list-form_label"
              >
                Address:<span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                className="form-control customer-form-control"
                id="inputAddress"
                placeholder="Address"
                name="address"
                onChange={handleInputChange}
                value={formData.address}
              />
              {/* {errors.address && (
                    <span className="customer-error">{errors.address}</span>
                  )} */}
            </div>

            <div className="form-group customer-list-form-group">
              <label
                htmlFor="Issue"
                className="form_label customer-list-form_label"
              >
                Issue:
              </label>
              <input
                type="text"
                className="form-control customer-form-controll"
                id="Issue"
                placeholder="Describe your issue"
                name="issue"
                value={formData.issue}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group customer-list-form-group">
              <label
                htmlFor="therapy"
                className="form_label customer-list-form_label"
              >
                Therapy:
              </label>
              <input
                type="text"
                className="form-control customer-form-control"
                id="therapy"
                placeholder="Describe therapy you want."
                name="therapy"
                value={formData.therapy}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group customer-list-form-group">
              <div className="row">
                {/* <div className="col col-md-5">
                      <label
                        htmlFor="Gender"
                        className="form_label customer-list-form_label"
                      >
                        Gender:
                      </label>
                      <select
                        className="form-control customer-form-control"
                        id="Gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      {errors.gender && (
                        <span className="customer-error">{errors.gender}</span>
                      )}
                    </div> */}
                <div className="col col-md-7">
                  <label
                    htmlFor="preferGender"
                    className="form_label customer-list-form_label"
                  >
                    Prefer Gender:<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-control customer-form-control"
                    id="preferGender"
                    name="prefgender"
                    value={formData.prefgender}
                    onChange={handleInputChange}
                  >
                    <option value="">Preferred Gender</option>
                    <option value="male">Male Therapist</option>
                    <option value="female">Female Therapist</option>
                    <option value="any">Any</option>
                  </select>
                  {/* {errors.preferGender && (
                        <span className="customer-error">
                          {errors.preferGender}
                        </span>
                      )} */}
                </div>

                <div className="col col-md-2">
                  <label
                    htmlFor="Age"
                    className="form_label customer-list-form_label"
                  >
                    Age:<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control customer-form-control"
                    id="Age"
                    name="age"
                    placeholder="Age"
                    maxLength="3"
                    inputMode="numeric"
                    value={formData.age}
                    onChange={handleInputChange}
                  ></input>
                  {/* {errors.gender && (
                    <span className="customer-error">{errors.gender}</span>
                  )} */}
                </div>
              </div>
            </div>

            <div className="form-group customer-list-form-group">
              <div className="row">
                <div className="col col-md-5">
                  <label
                    htmlFor="Date"
                    className="form_label customer-list-form_label"
                  >
                    Date:<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    id="Date"
                    className="form-control customer-form-control"
                    name="adate"
                    value={formData.adate}
                    onChange={handleInputChange}
                  />

                  {/* {errors.gender && (
                        <span className="customer-error">{errors.gender}</span>
                      )} */}
                </div>
                <div className="col col-md-4">
                  <label
                    htmlFor="time"
                    className="form_label customer-list-form_label"
                  >
                    Time:<span style={{ color: "red" }}>*</span>
                  </label>

                  <input
                    type="time"
                    id="time"
                    className="form-control customer-form-control"
                    name="atime"
                    value={formData.atime}
                    onChange={handleInputChange}
                  />

                  {/* {errors.preferGender && (
                        <span className="customer-error">
                          {errors.preferGender}
                        </span>
                      )} */}
                </div>
              </div>
            </div>
          </form>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button> */}
          <Button 
  variant="primary" 
  onClick={handleUpdate} 
  disabled={isUpdating} 
  className="btn-update d-flex align-items-center"
>
  {isUpdating && <Spinner animation="border" size="sm" className="loader-icon" />}
  <span className={isUpdating ? "text-status ms-2" : ""}>
    {isUpdating ? "Updating..." : "Update"}
  </span>
</Button>


        </Modal.Footer>
      </Modal>
      <Paginate_Admin
        totalItems={customers.length} // Total items is the length of all customers
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />

<ConfirmBox
        show={showConfirmBox}
        message={"Are you sure you want to delete the Customer"}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmBox(false)}
      />
    </div>
  );
};

export default CustomerList;
