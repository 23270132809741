
import { GETMASSAGEREMAIL, GETMASSAGERID } from "../Action/type/type"

// reducers/counterReducer.js
const initialState = {
  email: '',
  massager_id: ''
}

export const MasssageValueGet = (state = initialState, action) => {

  switch (action.type) {
    case GETMASSAGEREMAIL:

      return {

        ...state,
        email: action.payload

      }

    case GETMASSAGERID:
      return {
        ...state,
        massager_id: action.payload
      }
    default:
      return state
  }
}