import React from "react"; 
import { Col, Row } from "react-bootstrap"; 
import { FaSpa, FaHandSparkles, FaRegHandPaper } from "react-icons/fa";
import { IoIosBody, IoMdCog } from "react-icons/io";
import { MdOutlineFace, MdOutlineAccessibility } from "react-icons/md";
import { GiPressureCooker } from "react-icons/gi";
import "./AdvanceMassageTherapy.css";
import Tilt from 'react-parallax-tilt';
// Images 
import Adv_mas_img from "./Images/full_body_massage.jpg";
import Ayu_mas from "./Images/ayurvedic_massage1.jpg";
import dep_ti_mas from './Images/Deep_Tissue_Massage.jpg';
import sig_mas from './Images/Signature_Massage.jpg';
import swa_mas from './Images/swedish_massage.jpg';
import ful_bo_joi from './Images/Full_Body_Joints_Pain_Relief_Massage1.jpg';
import pain_Relief_Cupping from './Images/Pain_Relief_Cupping.jpg';
import sciatica_Pain_Relief_Massage from './Images/Sciatica_Pain_Relief_Massage.jpg';
import frozen_Shoulder_Massage1 from './Images/Frozen_Shoulder_Massage1.jpg';
import cervical_Pain_Relief_Massage from './Images/Cervical_Pain_Relief_Massage.jpg';
import high_Blood_Pressure_Massage1 from './Images/High_Blood_Pressure_Massage1.jpg';
import knee_Pain_Relief_Massage from './Images/Knee_Pain_Relief_Massage.jpg';
import foot_Reflexology from './Images/Foot_Reflexology.jpg';
import head_And_Face_Massage from './Images/Head_And_Face_Massage.jpg';
import foot_And_Ankle_Pain_Relief_Massage from './Images/Foot_And_Ankle_Pain_Relief_Massage.jpg';
import potli_Massage from './Images/Potli_Massage.jpg';
import aloevera_Gel_Massage from './Images/Aloevera_Gel_Massage.jpg';
import powder_Massage from './Images/Powder_Massage.jpg';
import cream_Massage from './Images/Cream_Massage.jpg';
import and_More_Treatments from './Images/And_More_Treatments.jpg';

// Define the treatments array with unique IDs for keys
const treatments = [
  {
    id: 1,
    name: "Full Body Massage",
    description:
      "A comprehensive massage targeting all major muscle groups to relieve tension and promote relaxation.",
    icon: <IoIosBody />,
    bgimg: Adv_mas_img 
  },
  {
    id: 2,
    name: "Ayurvedic Massage",
    description:
      "A traditional Indian massage technique that uses herbal oils and special techniques to balance the body's energies.",
    icon: <FaSpa />,
    bgimg: Ayu_mas,
  },
  {
    id: 3,
    name: "Deep Tissue Massage",
    description:
      "Focuses on deeper layers of muscle tissue to relieve chronic pain and tension.",
    icon: <FaHandSparkles />,
    bgimg: dep_ti_mas, 
  },
  {
    id: 4,
    name: "Signature Massage",
    description:
      "A unique blend of techniques tailored to your specific needs and preferences.",
    icon: <IoMdCog />,
    bgimg: sig_mas,
  },
  {
    id: 5,
    name: "Swedish Massage",
    description:
      "A gentle massage aimed at improving circulation and reducing stress.",
    icon: <FaSpa />,
    bgimg: swa_mas,
  },
  {
    id: 6,
    name: "Full Body Joints Pain Relief Massage",
    description:
      "Targets joint pain throughout the body with specialized techniques and oils.",
    icon: <GiPressureCooker />,
    bgimg: ful_bo_joi,
  },
  {
    id: 7,
    name: "Pain Relief Cupping",
    description:
      "Uses suction cups to alleviate pain and promote healing in specific areas.",
    icon: <FaSpa />,
    bgimg: pain_Relief_Cupping
  },
  {
    id: 8,
    name: "Sciatica Pain Relief Massage",
    description:
      "Focuses on relieving the symptoms of sciatica through targeted massage techniques.",
    icon: <GiPressureCooker />,
    bgimg: sciatica_Pain_Relief_Massage,
  },
  {
    id: 9,
    name: "Frozen Shoulder Massage",
    description:
      "Addresses stiffness and pain in the shoulder with specialized massage techniques.",
    icon: <FaRegHandPaper />,
    bgimg: frozen_Shoulder_Massage1,
  },
  {
    id: 10,
    name: "Cervical Pain Relief Massage",
    description:
      "Helps relieve pain and tension in the cervical (neck) region.",
    icon: <FaRegHandPaper />,
    bgimg: cervical_Pain_Relief_Massage,
  },
  {
    id: 11,
    name: "High Blood Pressure Massage",
    description:
      "Designed to help lower blood pressure through relaxation and stress reduction techniques.",
    icon: <FaSpa />,
    bgimg: high_Blood_Pressure_Massage1,
  },
  {
    id: 12,
    name: "Knee Pain Relief Massage",
    description:
      "Targets pain and discomfort in the knee area with specific massage techniques.",
    icon: <MdOutlineAccessibility />,
    bgimg: knee_Pain_Relief_Massage,
  },
  {
    id: 13,
    name: "Foot Reflexology",
    description:
      "Focuses on pressure points in the feet to promote overall health and wellness.",
    icon: <MdOutlineFace />,
    bgimg: foot_Reflexology,
  },
  {
    id: 14,
    name: "Head And Face Massage",
    description:
      "Relieves tension and promotes relaxation in the head and facial muscles.",
    icon: <MdOutlineFace />,
    bgimg: head_And_Face_Massage,
  },
  {
    id: 15,
    name: "Foot And Ankle Pain Relief Massage",
    description:
      "Targets pain and discomfort in the feet and ankles with specialized techniques.",
    icon: <MdOutlineAccessibility />,
    bgimg: foot_And_Ankle_Pain_Relief_Massage,
  },
  {
    id: 16,
    name: "Potli Massage",
    description:
      "Uses herbal pouches to provide warmth and relief to sore muscles.",
    icon: <FaSpa />,
    bgimg: potli_Massage,
  },
  {
    id: 17,
    name: "Aloevera Gel Massage",
    description:
      "Uses soothing aloe vera gel to hydrate and relieve skin tension.",
    icon: <FaSpa />,
    bgimg: aloevera_Gel_Massage,
  },
  {
    id: 18,
    name: "Powder Massage",
    description:
      "Involves the use of herbal powders to massage and rejuvenate the skin.",
    icon: <FaSpa />,
    bgimg: powder_Massage,
  },
  {
    id: 19,
    name: "Cream Massage",
    description:
      "Uses nourishing creams to hydrate and relax the skin while providing relief to muscles.",
    icon: <FaSpa />,
    bgimg: cream_Massage,
  },
  {
    id: 20,
    name: "And More Treatments...",
    description:
      "Explore additional treatments tailored to your specific needs.",
    icon: <FaSpa />,
    bgimg: and_More_Treatments,
  },
];

const AdvanceMassageTherapy = () => {

  return (
    <div className="adv-treatments-container">
      <h1 className="adv-treatments-heading">Advance Massage Therapy</h1>
      <Row className="adv-treatments-list">
        {treatments.map((treatment) => (
          <Col xs={6} md={4} lg={3} key={treatment.id} className="mb-4">
            <Tilt 
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              scale={1.05}
              transitionSpeed={250}
            >
              <div className="adv-treatment-fullscreen">
                <div className="adv-treatment-image">
                  <img src={treatment.bgimg} alt={treatment.name} />
                </div>
                <div className="adv-treatment-message">
                  <div className="adv-icon-container">{treatment.icon}</div>
                  <h3>{treatment.name}</h3>
                  <p>{treatment.description}</p>
                </div>
              </div>
            </Tilt>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AdvanceMassageTherapy;
