import React from 'react';

const Unauthorized = () => {
    return ( 
         <>
           <h1>You are unauthorized</h1>
         </>
       );
}

export default Unauthorized