import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Image, NavDropdown } from "react-bootstrap";
import RoleSelection from "../Login/RoleSelection";
import Login from "../Login/Login";
// import Navlogo from "./Navlogo1.jpg";
import Navlogo from "../Footer/shreekrushna-logo-min.png";
import "./Navpage.css";
import CustomerRegistractionForm2 from "../Customer Panel/Customer_Registraction_Form2";
import { useNavigate } from "react-router-dom";

function Navpage() {
  const [showRoleSelection, setShowRoleSelection] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const location = useLocation(); // Get current location

  const navigate = useNavigate();
  const navbarRef = useRef(null); // Create a reference for the navbar

  const handleShowRoleSelection = () => {
    setShowRoleSelection(true);
    setExpanded(false);
  };

  const handleCloseRoleSelection = () => setShowRoleSelection(false);

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setShowRoleSelection(false);
    setShowLogin(true);
  };

  const handleLoginClose = () => {
    setShowLogin(false);
    setSelectedRole("");
  };

  const handleNavLinkClick = (notog, dropdown) => {
    if (notog === "notog") {
      if (dropdown === "dropdown") {
        setExpanded(false);
      } else {
        setExpanded((prev) => !prev);
      }
    } else {
      setExpanded(false);
    }
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const openBookingModal = () => {
    setShowBookingModal(true);
    setExpanded(false);
  };

  const closeBookingModal = () => {
    setShowBookingModal(false);
  };

  const isActive = (path) => location.pathname === path; // Function to check active path

  // Effect to handle clicks outside of the navbar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Navbar
        ref={navbarRef}
        expand="lg"
        className="nav-panel"
        expanded={expanded}
      >
        <Container style={{ maxWidth: "100%" }}>
          <Navbar.Brand as={Link} to="/home" className="navbar-brand-custom">
            <Image src={Navlogo} className="nav-logo" />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="custom-toggler"
            onClick={() => setExpanded(!expanded)}
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <div className="nav-links-container" data-bs-theme="light">
              <Nav className="nav-links1">
                <Nav.Link
                  as={Link}
                  to="/home"
                  className={`nav-link1 ${
                    isActive("/home") ? "active-link" : ""
                  }`}
                  onClick={handleNavLinkClick}
                >
                  HOME
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/aboutus"
                  className={`nav-link1 ${
                    isActive("/aboutus") ? "active-link" : ""
                  }`}
                  onClick={handleNavLinkClick}
                >
                  ABOUT US
                </Nav.Link>

                <NavDropdown
                  title={
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/treatments");
                      }}
                    >
                      OUR TREATMENTS
                    </span>
                  }
                  id="basic-nav-dropdown"
                  show={dropdownOpen}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="nav-link-ourtreatment-dropdown"
                >

                  <NavDropdown.Item
                    className={`nav-dropdown-item ${
                      isActive("/bone-alignment-therapy") ? "active-link" : ""
                    }`}
                    as={Link}
                    to="/bone-alignment-therapy"
                    onClick={() => handleNavLinkClick("notog", "dropdown")}
                  >
                    <i className="fas fa-bone"></i>
                    Bone Alignment Therapy
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    className={`nav-dropdown-item ${
                      isActive("/advance-massage-therapy") ? "active-link" : ""
                    }`}
                    as={Link}
                    to="/advance-massage-therapy"
                    onClick={() => handleNavLinkClick("notog", "dropdown")}
                  >
                    <i className="fas fa-spa"></i>
                    Advance Massage Therapy
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    className={`nav-dropdown-item ${
                      isActive("/neuro-therapy") ? "active-link" : ""
                    }`}
                    as={Link}
                    to="/neuro-therapy"
                    onClick={() => handleNavLinkClick("notog", "dropdown")}
                  >
                    <i className="fas fa-brain"></i>
                    Neuro Therapy
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    className={`nav-dropdown-item ${
                      isActive("/chiropractic-adjustment") ? "active-link" : ""
                    }`}
                    as={Link}
                    to="/chiropractic-adjustment"
                    onClick={() => handleNavLinkClick("notog", "dropdown")}
                  >
                    <i className="fas fa-user-md"></i>
                    Chiropractic Adjustment
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    className={`nav-dropdown-item ${
                      isActive("/cupping-therapy") ? "active-link" : ""
                    }`}
                    as={Link}
                    to="/cupping-therapy"
                    onClick={() => handleNavLinkClick("notog", "dropdown")}
                  >
                    {/* <i className="fas fa-tint"></i> */}
                    <i className="fas fa-glass-whiskey"></i>
                    Cupping Therapy [ Hijama ]
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className={`nav-dropdown-item ${
                      isActive("/Waxing") ? "active-link" : ""
                    }`}
                    as={Link}
                    to="/Waxing"
                    onClick={() => handleNavLinkClick("notog", "dropdown")}
                  >
                    {/* <i className="fas fa-waxing"></i> */}
                    {/* <i className="fas fa-spa"></i> */}
                    <i className="fas fa-leaf"></i>


                    Waxing
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link
                  as={Link}
                  to="/benefits"
                  className={`nav-link1 ${
                    isActive("/benefits") ? "active-link" : ""
                  }`}
                  onClick={handleNavLinkClick}
                >
                  BENEFITS OF MASSAGE
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contactus"
                  className={`nav-link1 ${
                    isActive("/contactus") ? "active-link" : ""
                  }`}
                  onClick={handleNavLinkClick}
                >
                  CONTACT US
                </Nav.Link>

                <Nav.Link
                  as={Link}
                  to="/certification"
                  className={`nav-link1 ${
                    isActive("/certification") ? "active-link" : ""
                  }`}
                  onClick={handleNavLinkClick}
                >
                  OUR CERTIFICATIONS
                </Nav.Link>

                <Nav.Link className="nav-link1" onClick={openBookingModal}>
                  BOOK NOW
                </Nav.Link>
              </Nav>
            </div>
            <Nav>
              <Nav.Link
                href="#login"
                className="nav-link1 log-in-button"
                onClick={handleShowRoleSelection}
              >
                LOG IN
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Role Selection Modal */}
      {showRoleSelection && (
        <RoleSelection
          show={showRoleSelection}
          handleClose={handleCloseRoleSelection}
          onRoleSelect={handleRoleSelect}
        />
      )}

      {/* Login Modal */}
      {showLogin && (
        <Login
          show={showLogin}
          handleClose={handleLoginClose}
          role={selectedRole}
        />
      )}

      {/* Booking Modal */}
      {showBookingModal && (
        <CustomerRegistractionForm2
          show={showBookingModal}
          handleClose={closeBookingModal}
        />
      )}
    </>
  );
}

export default Navpage;
