import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import ToasterS from "../Customer Panel/Toaster";
import ToasterF from "../Customer Panel/ToasterF";
import CryptoJS from "crypto-js"; // Import CryptoJS for hashing
import api from "../../api";

const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),

  otp: Yup.string().when("otpSent", {
    is: true,
    then: Yup.string()
      .matches(/^\d{4}$/, "OTP must be exactly 4 digits")
      .required("OTP is required"),
  }),

  newPassword: Yup.string().when("otpSent", {
    is: true,
    then: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("New Password is required"),
  }),

  confirmPassword: Yup.string().when("otpSent", {
    is: true,
    then: Yup.string()
      .oneOf([Yup.ref("newPassword"), "null"], "Passwords must match")
      .required("Confirm Password is required"),
  }),
});

const ForgetPassword = ({ onBack, role }) => {
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const [showToastFail, setShowToastFail] = useState(false);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => setShowToast(false), 3000); // Toast duration
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const hashPassword = (password) => {
    return CryptoJS.SHA256(password).toString(); // Hash the password using SHA-256
  };

  return (
    <>
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />

      <Formik
        initialValues={{
          email: "",
          otp: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={ForgetPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!otpSent) {
            setLoading(true); // Start loading when sending OTP

            api
              .post(
                "/EmailForgetPassword.php",
                { email: values.email, role: role }
              )
              .then((response) => {
                setLoading(false); // Stop loading
                if (response.data.message === "Email does not exist") {
                  setOtpSent(false);
                  setToastMessage("Email does not exist.");
                  setShowToastFail(true); // Use a separate state for failure toaster
                  // alert("Email does not exist.");
                } else if (response.data.message === "Message could not be sent.") {
                  setOtpSent(false);
                  setToastMessage("Mail could not be sent.Contact Admin");
                  setShowToastFail(true); // Use a separate state for failure toaster
                }

                else {
                  setOtpSent(true);
                  setToastMessage("OTP has been sent to your email.");
                  setShowToast(true);
                }
              })
              .catch((error) => {
                setLoading(false); // Stop loading
                setToastMessage("Failed to send OTP. Please try again.");
                setShowToastFail(true);
              });
          } else {

            if (values.newPassword !== values.confirmPassword) {
              setToastMessage("Passwords do not match.");
              setShowToastFail(true);
              setSubmitting(false);
              return; // Prevent submission
            }

            if(values.newPassword.length == ""){
              setToastMessage("Passwords should not be empty.");
              setShowToastFail(true);
              setSubmitting(false);
              return;
            }
            setLoading(true); // Start loading when resetting password
            const hashedPassword = hashPassword(values.newPassword);
            api
              .post("/NewPassword.php", {
                role: role,
                email: values.email,
                otp: values.otp,
                newPassword: hashedPassword,
              })
              .then((response) => {
                setLoading(false); // Stop loading
                if (response.data.status === "error") {
                  setToastMessage(response.data.message);
                  setShowToastFail(true);
                } else {
                  setToastMessage("Password has been reset successfully!");
                  setShowToast(true);
                  setTimeout(() => {
                    onBack();
                  }, 2000);
                }
              })
              .catch((error) => {
                setLoading(false); // Stop loading
                setToastMessage("Failed to reset password. Please try again.");
                setShowToastFail(true);
              });
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="email">Email: <span style={{ color: "red" }}>*</span></label>
              <Field
                type="email"
                name="email"
                className="form-control mt-1 mb-2"
                placeholder="Enter email"
              />
              <ErrorMessage
                component="div"
                name="email"
                className="invalid-feedback"
              />
            </div>
            {otpSent && (
              <>
                <div className="form-group">
                  <label htmlFor="otp">OTP: <span style={{ color: "red" }}>*</span></label>
                  <Field
                    type="text"
                    name="otp"
                    className="form-control mt-1 mb-2"
                    placeholder="Enter OTP"
                    maxLength="4"
                    onInput={(e) => {
                      const value = e.target.value;
                      // Sanitize input to allow only numbers
                      e.target.value = value.replace(/[^0-9]/g, ""); // Only numbers allowed
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name="otp"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newPassword">New Password: <span style={{ color: "red" }}>*</span></label>
                  <Field
                    type="password"
                    name="newPassword"
                    className="form-control mt-1 mb-2"
                    placeholder="Enter new password"
                  />
                  <ErrorMessage
                    component="div"
                    name="newPassword"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm New Password: <span style={{ color: "red" }}>*</span></label>
                  <Field
                    type="password"
                    name="confirmPassword"
                    className="form-control mt-1 mb-2"
                    placeholder="Confirm new password"
                  />
                  <ErrorMessage
                    component="div"
                    name="confirmPassword"
                    className="invalid-feedback"
                  />
                </div>
              </>
            )}
            <button
              type="submit"
              className="btn btn-primary btn-block mt-3"
              disabled={isSubmitting || loading} // Disable if submitting or loading
            >
              {loading ? (
                <span>
                  <div className="loader" style={{ display: 'inline-block', marginRight: '8px' }}></div>
                  Loading...
                </span>
              ) : (
                (otpSent ? "Reset Password" : "Send OTP")
              )}
            </button>
            <button
              type="button"
              className="btn btn-link mt-2"
              onClick={onBack}
            >
              Back to Login
            </button>
            <style jsx>{`
              .loader {
                border: 3px solid #f3f3f3; /* Light grey */
                border-top: 3px solid #3498db; /* Blue */
                border-radius: 50%;
                width: 20px;
                height: 20px;
                animation: spin 1s linear infinite;
                display: inline-block;
              }
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}</style>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgetPassword;
