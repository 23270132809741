import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./massagerdashboard.css";
import { useSelector } from "react-redux";
import ToasterS from "../../Customer Panel/Toaster";
import ToasterF from "../../Customer Panel/ToasterF";
import LoadingSpinner from "../../Loader/Loader";
import api from "../../../api";

const MassagerDashBoard = () => {
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const [showToastFail, setShowToastFail] = useState(false);
  const MassagerEmail = useSelector((state) => state.MassagerValueGat.email);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCount = async () => {
      const payload = { email: MassagerEmail };
      try {
        // Simulate a loading delay
        await new Promise((resolve) => setTimeout(resolve, 100)); // Delay for 1 second

        const response = await api.post(
          "/MassagerPanelCount.php",
          payload
        );

        if (response.data.status === "success") {
          setCount(response.data.data);
        } else {

          setToastMessage("Error fetching count.");
          setShowToastFail(true);
        }
      } catch (error) {

        setToastMessage("Error fetching count.");
        setShowToastFail(true);
      } finally {
        setLoading(false); // Set loading to false after data fetching
      }
    };

    fetchCount();
  }, [MassagerEmail]);

  const handleCardClick = (path) => {
    navigate(path);
  };

  if (loading) {
    return <LoadingSpinner />;
  }


  return (
    <div className="container-fluid">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Massager Dashboard</h1>
      </div>

      
        <>
          {/* Content Row */}
          <div className="row justify-content-center m-1">
            <div
              className="col-xl-5 col-md-6 mb-4 m-2"
              onClick={() =>
                handleCardClick("/massager-panel/massagercustomerlist")
              }
            >
              <div className="card shadow h-100 py-2 Massagercardborder4">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Total Customer
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {count.totalCustomerCount || 0}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-users fa-2x text-gray-300 text-gray-custom ml-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-5 col-md-6 mb-4 m-2"
              onClick={() =>
                handleCardClick("/massager-panel/massageracceptorder")
              }
            >
              <div className="card shadow h-100 py-2 Massagercardborder">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        Accepted Order
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {count.totalAcceptedOrders || 0}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-check-circle fa-2x text-gray-custom ml-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="row justify-content-center m-1">
            {/* <div className="col-xl-5 col-md-6 mb-4 m-2">
                            <div className="card shadow h-100 py-2 Massagercardborder3">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Rejected Order
                                            </div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                                                {count.totalRejectedOrders || 0}
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fas fa-times-circle fa-2x text-gray-300 text-gray-custom ml-2"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            <div
              className="col-xl-5 col-md-6 mb-4 m-2"
              onClick={() =>
                handleCardClick("/massager-panel/massagercomponentorder")
              }
            >
              <div className="card shadow h-100 py-2 Massagercardborder2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                        Completed Order
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {count.totalCompletedOrders || 0}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-clipboard-check fa-2x text-gray-300 text-gray-custom ml-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      
    </div>
  );
};

export default MassagerDashBoard;
