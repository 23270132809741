import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Spinner } from "react-bootstrap";
import "./admindashboard.css";
import "./customerlist.css";
import ToasterS from "../../Customer Panel/Toaster";
import ToasterF from "../../Customer Panel/ToasterF";
import LoadingSpinner from "../../Loader/Loader";
import api from "../../../api";

const AddVideo = () => {
  const [videoLinks, setVideoLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);
  const [newLink, setNewLink] = useState("");
  const [newName, setNewName] = useState("");
  const [linkId, setNewId] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading
  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [showToast, setShowToast] = useState(false); // State for controlling toast visibility
  const [showToastFail, setShowToastFail] = useState(false);

  // Fetch initial video links from the database
  useEffect(() => {
    const fetchVideoLinks = async () => {
      setLoading(true); // Start loading
      try {
        const response = await api.get(
          "/AdminPanelFeedbackVideo.php"
        );
        setVideoLinks(response.data.data);
      } catch (error) {
        // alert("Error fetching video links. Please try again.");
        setToastMessage("Error fetching video links. Please try again.");
        setShowToastFail(true);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchVideoLinks();
  }, []);

  // Handle updating a video link
  const handleUpdateLink = async () => {
    if (!selectedLink) {
      // alert("No link selected for update.");
      setToastMessage("No link selected for update.");
      setShowToastFail(true);
      return;
    }
    setLoading(true);
    try {
      const updatedLink = {
        feedbackID: linkId,
        feedbackLink: newLink,
        feedbackName: newName,
      };
      await api.post(
        "/AdminPanelFeedbackUpdate.php",
        updatedLink
      );
      const updatedLinks = videoLinks.map((link) =>
        link.feedbackID === linkId
          ? { ...link, feedbackLink: newLink, feedbackName: newName }
          : link
      );

      setToastMessage("Link updated..");
      setShowToast(true);
      setVideoLinks(updatedLinks);
      setShowUpdateModal(false);
      setNewLink("");
      setNewName("");
      setLoading(false);
    } catch (error) {
      // alert("Error updating video link. Please try again.");
      setToastMessage("Error updating video link. Please try again.");
      setShowToastFail(true);
      setLoading(false);
    }
  };

  // Handle opening the update modal
  const handleOpenUpdateModal = (link) => {
    setSelectedLink(link);
    setNewLink(link.feedbackLink);
    setNewName(link.feedbackName);
    setNewId(link.feedbackID);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedLink(null);
    setNewLink("");
    setNewName("");
  };

  // Function to extract the YouTube video ID safely
  const getVideoID = (link) => {
    if (!link) return null;
    const match = link.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/
    );
    return match ? match[1] : null;
  };

  if (loading) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <div className="dashhead">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />

      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />
      <h2>Update Video Links</h2>

      {loading && ( // Show spinner if loading
        <div className="custom-loading-spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <Table responsive bordered hover className="mt-3 responsive-table">
        <thead>
          <tr className="text-center align-middle">
            <th>Name</th>
            <th>Video Link</th>
            <th>Preview</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(videoLinks) &&
            videoLinks.map((link) => (
              <tr key={link.feedbackID} className="align-middle">
                <td>{link.feedbackName}</td>
                <td>{link.feedbackLink}</td>
                <td className="text-center align-middle">
                  <iframe
                    height="80px"
                    width="150px"
                    src={`https://www.youtube.com/embed/${getVideoID(
                      link.feedbackLink
                    )}`}
                    title="YouTube Video"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </td>
                <td className="text-center align-middle">
                  <Button
                    variant="warning"
                    onClick={() => handleOpenUpdateModal(link)}
                  >
                    Update
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {/* Update Modal */}
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Video Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="updateVideoName">
              <Form.Label>New Video Name</Form.Label>
              <Form.Control
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="updateVideoLink">
              <Form.Label>New Video Link</Form.Label>
              <Form.Control
                type="text"
                value={newLink}
                onChange={(e) => setNewLink(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateLink}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddVideo;
