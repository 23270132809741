import React from "react";
import { Col, Row } from "react-bootstrap"; // Import Spinner
import { FaRegHandPaper, FaFire } from "react-icons/fa";
import { IoIosBody } from "react-icons/io";
import { MdOutlineAccessibility } from "react-icons/md";
import { GiCactus, GiHealthPotion } from "react-icons/gi";
import "./CuppingTherapy.css";
import Tilt from "react-parallax-tilt";
// Images import
import full_Body_Detoxin from "./Images/Full_Body_Detoxin_[Hijama].jpg";
import half_Body_Detoxin from "./Images/Half_Body_Detoxin_[Hijama].jpg";
import migraine_Headache_Treatment from "./Images/Migraine_Headache_Treatment.jpg";
import pain_Relief_Cupping from "./Images/Pain_Relief_Cupping.avif";
import fire_cupping from "./Images/Pain_Relief_Cupping.jpg";
import air_Cupping from "./Images/Air_Cupping.jpg";
import bamboo_Cupping from "./Images/Bamboo_Cupping.jpg";
import and_More_Treatments from "./Images/And_More_Treatments.jpg";

// Define the cupping treatments array
const treatments = [
  {
    id: 1,
    name: "Full Body Detoxin [Hijama]",
    description:
      "A comprehensive detox treatment using cupping to cleanse the body and improve overall health.",
    icon: <IoIosBody />,
    bgimg: half_Body_Detoxin,
  },
  {
    id: 2,
    name: "Half Body Detoxin [Hijama]",
    description:
      "Targets specific areas of the body for detoxification and rejuvenation.",
    icon: <GiHealthPotion />,
    bgimg: full_Body_Detoxin,
  },
  {
    id: 3,
    name: "Migraine Headache Treatment [Hijama]",
    description:
      "Uses cupping techniques to alleviate migraine and headache symptoms.",
    icon: <FaRegHandPaper />,
    bgimg: migraine_Headache_Treatment,
  },
  {
    id: 4,
    name: "Pain Relief Cupping",
    description:
      "Specialized cupping techniques to relieve localized pain and discomfort.",
    icon: <GiCactus />,
    bgimg: pain_Relief_Cupping,
  },
  {
    id: 5,
    name: "Fire Cupping",
    description:
      "Involves using heat to create suction, enhancing the effectiveness of the cupping treatment.",
    icon: <FaFire />,
    bgimg: fire_cupping,
  },
  {
    id: 6,
    name: "Air Cupping",
    description:
      "Utilizes air suction to improve circulation and alleviate pain.",
    icon: <MdOutlineAccessibility />,
    bgimg: air_Cupping,
  },
  {
    id: 7,
    name: "Bamboo Cupping",
    description:
      "Incorporates bamboo cups for a unique cupping experience aimed at detoxification.",
    icon: <GiCactus />,
    bgimg: bamboo_Cupping,
  },
  {
    id: 8,
    name: "And More Treatments...",
    description:
      "Explore additional cupping treatments tailored to your needs.",
    icon: <GiHealthPotion />,
    bgimg: and_More_Treatments,
  },
];

const CuppingTherapy = () => {
  return (
    <div className="cupping-treatments-container">
      <h1 className="cupping-treatments-heading">Cupping Therapy [ Hijama ]</h1>
      <Row className="cupping-treatments-list">
        {treatments.map((treatment) => (
          <Col xs={6} md={4} lg={3} key={treatment.id} className="mb-4">
            <Tilt
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              scale={1.05}
              transitionSpeed={250}
            >
              <div className="cupping-treatment-fullscreen">
                <div className="cupping-treatment-image">
                  <img src={treatment.bgimg} alt={treatment.name} />
                </div>
                <div className="cupping-treatment-message">
                  <div className="cupping-icon-container">{treatment.icon}</div>
                  <h3>{treatment.name}</h3>
                  <p>{treatment.description}</p>
                </div>
              </div>
            </Tilt>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CuppingTherapy;
