import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Certification.css";
import Certificate1 from "./Certi/CHRRO_11zon_11zon.webp";
import Certificate2 from "./Certi/CUPPING 1_11zon_11zon.webp";
import Certificate3 from "./Certi/D CH T_11zon_11zon.webp";
import Certificate4 from "./Certi/MD CUP_11zon_11zon.webp";
import Certificate5 from "./Certi/MICROBAT 2_11zon_11zon.webp";
import Certificate6 from "./Certi/NERO 2_11zon_11zon.webp";
import Certificate7 from "./Certi/NEUROTHERAPY_11zon_11zon.webp";
import Certificate8 from "./Certi/MASSAGE_11zon_11zon.webp";
import Certificate9 from "./Certi/MASSAGE 2_11zon_11zon.webp";
import Certificate10 from "./Certi/MICROBAT 1_11zon_11zon.webp";

const certificates = [
  {
    id: 1,
    title: "Chiropractic Therapist",
    year: 2021,
    image: Certificate1,
  },
  {
    id: 2,
    title: "Diploma in Chiropractic Techniques",
    year: 2022,
    image: Certificate3,
  },
  {
    id: 3,
    title: "Cupping Therapist",
    year: 2023,
    image: Certificate2,
  },
  {
    id: 4,
    title: "Master Diploma in Cupping / Hijama Therapy",
    year: 2022,
    image: Certificate4,
  },
  {
    id: 5,
    title: "Advanc Level Microbat",
    year: 2022,
    image: Certificate5,
  },
  {
    id: 6,
    title: "Neurotherapist",
    year: 2022,
    image: Certificate6,
  },
  {
    id: 7,
    title: "Neurotherapy",
    year: 2021,
    image: Certificate7,
  },
  {
    id: 8,
    title: "Massage",
    year: 2023,
    image: Certificate8,
  },
  {
    id: 9,
    title: "Massage Therapy",
    year: 2023,
    image: Certificate9,
  },
  {
    id: 10,
    title: "JC'S BAT",
    year: 2023,
    image: Certificate10,
  },
];

const CertificatePage = () => {
  
  const [certList, setCertList] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCertList(certificates);
    }, 0);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div className="bgcertificate">
      <section className="Certificate_page_header">
        <h1 className="certificate_heading">Certificates Gallery</h1>
        <p className="cerheadinfo">
          Each certificate represents our commitment to excellence  and the
          dedication to providing you with the best therapeutic experiences.<br />
          <span className="Second-quote">
            "With a diverse range of therapies,we are committed to promoting holistic healing for mind, body and spirit."
          </span>
        </p>
      </section>
      <Container className="certificatecontainer">

        <Row className="single-row">
          {certList.slice(7).map((cert) => (
            <Col xs={12} sm={6} md={4} className="mb-5" key={cert.id}>
              <div className="certificate-wrapper">
                <img
                  src={cert.image}
                  alt={cert.title}
                  className="certificate-image"
                  loading="lazy"
                />
                <div className="certificate-info">
                  <h5 className="certificate-info-h5">{cert.title}</h5>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Row className="justify-content-center certificate-container">
          {certList.slice(0, 7).map((cert) => (
            <Col xs={12} sm={4} className="mb-5" key={cert.id}>
              <div className="certificate-wrapper">
                <img
                  src={cert.image}
                  alt={cert.title}
                  className="certificate-image"
                  loading="lazy"

                />
                <div className="certificate-info">
                  <h5 className="certificate-info-h5">{cert.title}</h5>
                </div>
              </div>
            </Col>
          ))}
          {/* <Col xs={12} className="centered-certificate mb-5">
            <div className="certificate-wrapper">
              <img
                src={certList[6].image}
                alt={certList[6].title}
                className="certificate-image"
              />
              <div className="certificate-info">
                <h5>{certList[6].title}</h5>
              </div>
            </div>
          </Col> */}
        </Row>

      </Container>
    </div>
  );
};

export default CertificatePage;
