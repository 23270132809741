import React  from "react"; // Import useState and useEffect
import { Col, Row } from "react-bootstrap"; // Import Spinner for loader
// import { FaBone } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
// import { GiArm } from "react-icons/gi";
import "./BoneAlignmentTherapy.css";
import Tilt from "react-parallax-tilt";
// Image imports
import MenWax from "./Images/Male-Body-Waxing-Embrace-it.webp";
import FemaleWaxing from "./Images/Female-waxing.jpg";
import and_More_Treatments from "./Images/And_More_Treatments.jpg";
import { FaFemale, FaMale } from "react-icons/fa";

// Define the bone alignment treatments array with unique IDs for keys
const treatments = [
  {
    id: 1,
    name: "Men's Waxing",
    description:
      "Full body waxing services tailored for men, including back, chest, and leg waxing.",
    icon: <FaMale />,
    bgimg: MenWax,
  },
  {
    id: 2,
    name: "Women's Waxing",
    description:
      "Comprehensive waxing solutions for women, including arms, legs, and bikini areas.",
    icon: <FaFemale />,
    bgimg: FemaleWaxing,
  },
  {
    id: 3,
    name: "And More Treatments...",
    description:
      "Explore additional treatments tailored to your bone alignment needs.",
    icon: <IoMdArrowDropdown />,
    bgimg: and_More_Treatments,
  },
];

const Waxing = () => {
  return (
    <div className="bone-treatments-container">
      <h1 className="bone-treatments-heading">Waxing</h1>
      <Row className="bone-treatments-list">
        {treatments.map((treatment) => (
          <Col xs={6} md={4} lg={3} key={treatment.id} className="mb-4">
            <Tilt
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              scale={1.05}
              transitionSpeed={250}
            >
              <div className="bone-treatment-fullscreen">
                <div className="bone-treatment-image">
                  <img src={treatment.bgimg} alt={treatment.name} />
                </div>
                <div className="bone-treatment-message">
                  <div className="bone-icon-container">{treatment.icon}</div>
                  <h3>{treatment.name}</h3>
                  <p>{treatment.description}</p>
                </div>
              </div>
            </Tilt>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Waxing;
