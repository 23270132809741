// import react from 'react';
// import Spinner from 'react-bootstrap/Spinner';

// const LodingSpinner = ()=> {
//   return (
//     <Spinner animation="border" role="status">
//       <span className="visually-hidden">Loading...</span>
//     </Spinner>
//   );
// }

// export default  LodingSpinner;


import React from 'react';
import { GridLoader} from 'react-spinners';

const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <GridLoader color="#28a745" />
    </div>
  );
};

export default LoadingSpinner;
