import React, { useState, useEffect } from 'react';
import './MassagerReport.css';
import { useSelector } from 'react-redux';
import ToasterS from '../../Customer Panel/Toaster'; // Import success toaster
import ToasterF from '../../Customer Panel/ToasterF'; // Import failure toaster
import Paginate from '../../Pagegnation/Pagination'; // Ensure this is the correct import
import pdfdownload from './pdf-download.svg';
import LoadingSpinner from '../../Loader/Loader';
import api from '../../../api';

const MassagerTotalOrder = () => {
  const [data, setData] = useState([]); // Initialize as an empty array
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [toastMessage, setToastMessage] = useState("");
  const [showToastFail, setShowToastFail] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Show 10 items per page
  const [totalItems, setTotalItems] = useState(0); // Total records from backend

  const MassagerEmail = useSelector(state => state.MassagerValueGat.email);

  // Function to get the first day of the current month and today's date
  const getInitialDates = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Format dates as 'YYYY-MM-DD'
    const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];
    const formattedToday = today.toISOString().split('T')[0];

    setStartDate(formattedFirstDay);
    setEndDate(formattedToday);

    return { start_date: formattedFirstDay, end_date: formattedToday };
  };

  const handleDateSubmit = (start_date, end_date, page) => {
    const payload = {
      start_date: start_date || startDate,
      end_date: end_date || endDate,
      report_type: "total_customers_by_massager",
      massager_email: MassagerEmail,
      page: page, // Send current page number
      records_per_page: itemsPerPage // Send records per page
    };

    setLoading(true); // Start loading
    api.post('/generate_customerofmassager.php', payload)
      .then(response => {
        if (Array.isArray(response.data.data)) {
          setData(response.data.data);
          setTotalItems(response.data.totalRecords || 0); // Ensure totalRecords has a fallback value
          if (response.data.data.length === 0) {
            setToastMessage("Data not found");
            setShowToastFail(true);
          } else {
            setToastMessage("Data retrieved successfully");
            setShowToast(true);
          }
        } else {
          setToastMessage("Unexpected data format");
          setShowToastFail(true);
          setData([]); // Reset data to an empty array
        }
      })
      .catch(error => {
        setToastMessage("An error occurred");
        setShowToastFail(true);
      })
      .finally(() => {
        setLoading(false); // End loading
      });
  };

  const handleDownloadPdf = () => {
    if (data.length === 0) {
      setToastMessage("Data not found");
      setShowToastFail(true);
      return; // Prevent further execution if there's no data
    }

    const payload = {
      start_date: startDate,
      end_date: endDate,
      massager_email: MassagerEmail
    };



    api.post('/MassagerTotalCustomerReportpdf.php', payload, {
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Total_Report.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        setToastMessage("Error submitting dates.");
        setShowToastFail(true);
      });
  };

  // Fetch data on component mount with default dates
  useEffect(() => {
    const initialDates = getInitialDates(); // Get the initial dates
    handleDateSubmit(initialDates.start_date, initialDates.end_date); // Fetch data automatically
  }, []); // Empty dependency array ensures this runs once on component mount

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
    handleDateSubmit(startDate, endDate, page); // Fetch data with new page
  };

  if (loading) {
    return <LoadingSpinner/>;
  }

  return (
    <div className="MassagerReport_container">
      <ToasterS
        message={toastMessage}
        show={showToast}
        onClose={() => setShowToast(false)}
      />
      <ToasterF
        message={toastMessage}
        show={showToastFail}
        onClose={() => setShowToastFail(false)}
      />

      <h2 className="MassagerReport_heading">Total Order</h2>

      <div className="MassagerReport_dateSelectors">
        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="MassagerReport_input"
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="MassagerReport_input"
          />
        </label>

        <div className="MassagerReport_buttonContainer">
          <button onClick={() => handleDateSubmit(startDate, endDate)} className="MassagerReport_button">
            Submit Dates
          </button>
        </div>

        <div className="MassagerReport_buttonContainer">
          <img
            src={pdfdownload}
            alt="Download PDF"
            onClick={handleDownloadPdf}
            className="Report_Massager_Page_downloadGif"
          />
        </div>
      </div>

      
        <table className="MassagerReport_table">
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Address</th>
              <th>Issue</th>
              <th>Therapy</th>
              <th>Gender</th>
              <th>Age</th>
              <th>PrefGender</th>
              <th>Booking Time</th>
              <th>Appointment</th>

            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (data
                .slice()
                .sort((a, b) => new Date(b.time) - new Date(a.time))
                .map((entry, index) => (
                  <tr key={index}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{entry.customer_name}</td>
                    <td>{entry.customer_contact}</td>
                    <td>{entry.customer_email}</td>
                    <td>{entry.customer_address}</td>
                    <td>{entry.customer_issue}</td>
                    <td>{entry.customer_therapy}</td>
                    <td>{entry.customer_gender}</td>
                    <td>{entry.age}</td>
                    <td>{entry.prefgender}</td>
                    <td>{entry.request_time}</td>
                    <td>{entry.appointment}</td>

                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="8">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
     
      {/* Pagination Component */}
      <Paginate
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default MassagerTotalOrder;
