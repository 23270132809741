import React, { useState, useEffect, useCallback } from "react";
import "./Toaster.css"; // Custom CSS for animation
import Toast from "react-bootstrap/Toast";

const ToasterS = ({ show, message, onClose }) => {
  const [isHiding, setIsHiding] = useState(false);

  const handleClose = useCallback(() => {
    setIsHiding(true);
    setTimeout(() => {
      onClose(); // Call parent component's function to hide toast
      setIsHiding(false); // Reset hiding state after animation
    }, 500); // Wait for animation to finish
  }, [onClose]); // Include onClose in the dependency array

  useEffect(() => {
    if (show) {
      setIsHiding(false); // Reset hiding state when toast is shown

      const timer = setTimeout(() => {
        handleClose(); // Automatically close after 5 seconds
      }, 5000); // 5 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [show, handleClose]); // Include show and handleClose in the dependency array

  return (
    <>
      {show && (
        <Toast
          className={`d-inline-block m-1 position-fixed top-0 end-0 p-3 ${
            isHiding ? "hide" : ""
          } SuccessToaster`}
          bg="success"
          onClose={handleClose}
          show={show}
        >
          <Toast.Header>
            <strong className="me-auto SuccessToasterHeader">Notification</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body className="SuccessToasterBody">
            {message}
          </Toast.Body>
        </Toast>
      )}
    </>
  );
};

export default ToasterS;
