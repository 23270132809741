// store.js
// import { createStore, combineReducers } from 'redux'
// import { MasssageValueGet } from '../Reducer/MasssagerInfoGetReducer';



// const rootReducer = combineReducers({
//       MassagerValueGat: MasssageValueGet
// })

// const store = createStore(rootReducer)

// export default 

// store.js

import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import { MasssageValueGet } from '../Reducer/MasssagerInfoGetReducer';

// Configuration for redux-persist
const persistConfig = {
  key: 'root', // Root key for persistence
  storage,     // Use localStorage to store Redux state
};

// Combine your reducers
const rootReducer = combineReducers({
  MassagerValueGat: MasssageValueGet, // Your massager data reducer
});

// Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
const store = createStore(persistedReducer);

// Create a persistor to persist the store
const persistor = persistStore(store);

export { store, persistor };


